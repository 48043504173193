import { ErrorHandler, Injectable } from '@angular/core';
import { SentryIonicErrorLogger } from 'src/app/sentry-ionic-error-logger.service';
import { LoadingHelper } from './helpers/loading-helper';
import { MessageHelper } from './helpers/message-helper';
import { ApiException } from 'src/core/common/domain/exceptions/api-exception';
import { ClientException } from 'src/core/common/domain/exceptions/client-exception';
import { Translation } from 'src/core/common/domain/translation/translation';
import { EventsService } from '../core/events/events.service';
import { AuthorizationExpiredEvent } from '../core/events/authorization-expired.event';
import { UnauthorizedException } from '../core/authentication/domain/exceptions/unauthorized-exception';
import { MasterDataIncoherenceException } from '../core/common/domain/exceptions/master-data-incoherence-exception';
import { ErrorCode } from '../core/common/domain/exceptions/error-code';

@Injectable({ providedIn: 'any' })
export class CustomErrorHandler implements ErrorHandler {
  private static readonly DISCARDING_ERRORS_WINDOW_TIME = 2000;
  private discardingErrors = false;

  constructor(
    private readonly externalErrorLogger: SentryIonicErrorLogger,
    private readonly eventsService: EventsService,
    private readonly loadingHelper: LoadingHelper,
    private readonly messageHelper: MessageHelper,
    private readonly translation: Translation
  ) {}

  async handleError(_error: any) {
    console.warn('Received unhandled error');

    // when error comes from a Promise rejection, the actual error comes inside error.rejection
    const error = _error?.rejection || _error;

    // Uncomment to see the error and the original error (if exists)
    this.printErrorToConsole(error);

    await this.tryLoCloseLoadingLayer();

    if (this.authorizationHasExpired(error)) {
      this.eventsService.publishAuthorizationExpired(new AuthorizationExpiredEvent());
      return;
    }

    if (this.discardingErrors) {
      // console.warn('Received error within discarding window time, skipping…');
      return;
    }
    this.activateSemaphoreDuring(CustomErrorHandler.DISCARDING_ERRORS_WINDOW_TIME);

    if (this.isApiError(error)) {
      await this.handleApiError(error);
      return;
    }

    // otherwise, client error
    await this.handleClientError(error);
  }

  private authorizationHasExpired(error: any): boolean {
    return error instanceof UnauthorizedException;
  }

  private isApiError(error: any): boolean {
    return error instanceof ApiException;
  }

  private async handleApiError(error: ApiException): Promise<void> {
    // if (error instanceof NoConnectionException) {
    //   await this.displayNoConnectionToast();
    //   return;
    // }
    //
    // if (error instanceof ServerIsGoneException) {
    //   await this.displayServerIsGoneToast();
    //   return;
    // }

    // log error (asynchronously) to external logger service (Crashlytics, Bugsnag, Sentry, etc.)
    this.externalErrorLogger.log(error, error.code).then().catch(console.error);

    console.log('error.message, error.code');
    console.log(error.message, error.code);

    // handle specific API errors, already sent to Sentry
    if (error.message === MasterDataIncoherenceException.NO_RESOLUTIONS_AND_TASK_HAS_NO_RESOLUTION) {
      const message = this.translation.instant('ERRORS.NO_RESOLUTIONS_AND_TASK_HAS_NO_RESOLUTION', {
        code: error.code,
      });
      await this.messageHelper.showError(message);
      return;
    }
    if (error.message === MasterDataIncoherenceException.MORE_THAN_ONE_RESOLUTION_BUT_CLOSE_MODAL_NOT_ENABLED) {
      const message = this.translation.instant('ERRORS.MORE_THAN_ONE_RESOLUTION_BUT_CLOSE_MODAL_NOT_ENABLED', {
        code: error.code,
      });
      await this.messageHelper.showError(message);
      return;
    }
    if (error.message === MasterDataIncoherenceException.MORE_THAN_ONE_RESOLUTION_BUT_REJECT_MODAL_NOT_ENABLED) {
      const message = this.translation.instant('ERRORS.MORE_THAN_ONE_RESOLUTION_BUT_REJECT_MODAL_NOT_ENABLED', {
        code: error.code,
      });
      await this.messageHelper.showError(message);
      return;
    }
    if (
      error.message ===
      MasterDataIncoherenceException.MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_CLOSE_MODAL
    ) {
      const message = this.translation.instant(
        'ERRORS.MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_CLOSE_MODAL',
        { code: error.code }
      );
      await this.messageHelper.showError(message);
      return;
    }
    if (
      error.message ===
      MasterDataIncoherenceException.MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_REJECT_MODAL
    ) {
      const message = this.translation.instant(
        'ERRORS.MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_REJECT_MODAL',
        { code: error.code }
      );
      await this.messageHelper.showError(message);
      return;
    }

    await this.displayErrorToastWithCode(error.code);
  }

  private async displayErrorToastWithCode(code: string | null): Promise<void> {
    const message = await this.translation.translate('MESSAGES.UNHANDLED_API_ERROR', { code });
    await this.messageHelper.showError(message);
  }

  private printErrorToConsole(error: Error | ApiException | ClientException) {
    console.log('Wrapped error');
    console.error(error);
    if (error instanceof ApiException || error instanceof ClientException) {
      if (error.originalError) {
        console.log(`Original error, with code ${error.code}`);
        console.error(error.originalError);
      }
    }
  }

  private activateSemaphoreDuring(time: number) {
    this.discardingErrors = true;
    setTimeout(() => {
      this.discardingErrors = false;
    }, time);
  }

  /**
   * In case any error occurs, we ensure that the loading layer is always removed
   * so that the user can continue to use the application.
   */
  private async tryLoCloseLoadingLayer() {
    await this.loadingHelper.end();
  }

  private async handleClientError(error: Error | ClientException): Promise<void> {
    let showDefaultMessage = true;
    if (error instanceof ClientException) {
      showDefaultMessage = error.showDefaultMessage;
    }
    // Log error (asynchronously) to external logger service (Crashlytics, Bugsnag, Sentry, etc.)
    // If error reaches here, it means it was not controlled in anywhere before, so we log it.
    const code = error instanceof ClientException ? error.code : ErrorCode.generateWithPrefix('C');

    this.externalErrorLogger.log(error, code).then().catch();

    const message = showDefaultMessage
      ? await this.translation.translate('MESSAGES.UNHANDLED_CLIENT_ERROR', { code })
      : error.message;

    await this.messageHelper.showError(message);
  }
}
