import { Task } from 'src/core/master-data/domain/task';
import { Nullable } from '../../../common/domain/types/types';
import { StartTaskQueueElement } from '../../../queue/domain/elements/start-task-queue-element';

export class StartTaskRequest {
  constructor(public readonly task: Task, public readonly queueElement: Nullable<StartTaskQueueElement>) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: StartTaskQueueElement) {
    return new this(element.task, element);
  }
}
