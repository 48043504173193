import * as moment from 'moment-timezone';
import { Nullable } from '../types/types';
import { DateRange } from '../../../master-data/domain/date-range';

export class LocalDate {
  readonly date: moment.Moment;
  readonly atom: string;
  readonly ymd: string;
  readonly dmy: string;
  readonly timestamp: string;

  private constructor(date?: string) {
    const tz = 'Europe/Madrid';
    this.date = !date ? moment() : moment(date);
    // store date in needed formats to save time in run-time
    this.date.tz(tz);
    this.atom = this.date.format();
    this.ymd = this.date.format('YYYY-MM-DD');
    this.dmy = this.date.format('DD/MM/YYYY');
    this.timestamp = this.date.format('x');
  }

  static now() {
    return new LocalDate();
  }

  static fromIsoString(atom: string): LocalDate | null {
    if (atom === '0001-01-01T00:00:00') {
      return null;
    }

    return new LocalDate(atom);
  }

  static fromFormat(date: string, format: string): LocalDate | null {
    const _d = moment(date, format);
    if (!_d.isValid()) {
      return null;
    }

    return LocalDate.fromIsoString(_d.toISOString());
  }

  static fromTimestamp(timestamp: number) {
    const atom = moment(timestamp).format();
    return new LocalDate(atom);
  }

  static fromString(input?: string): Nullable<LocalDate> {
    if (!input) {
      return null;
    }

    return new LocalDate(input);
  }

  inRange(range: DateRange): boolean {
    const from = range.from.atom;
    const to = range.to.atom;
    const date = this.atom;

    return date >= from && date <= to;
  }

  toFormat(format: string) {
    return this.date.format(format);
  }

  formatToDMYDynamic(): string {
    const today = LocalDate.now().date;
    if (today.isSame(this.date, 'year')) {
      return this.date.format('DD/MM');
    }

    return this.date.format('DD/MM/YY');
  }

  discardTime() {
    const date = this.date;
    date.hours(0).minutes(0).seconds(0);
    return new LocalDate(date.format());
  }
}
