import { Injectable } from '@angular/core';
import { MasterDataRepository } from '../../domain/master-data-repository';
import { MasterDataService } from '../../domain/master-data-service';
import { MasterData } from '../../domain/master-data';
import { TaskService } from '../../../tasks/domain/task.service';
import { FiltersService } from '../../../filters/domain/filters-service';
import { EventsService } from '../../../events/events.service';
import { MasterDataUpdatedEvent } from '../../../events/master-data-updated.event';

@Injectable({
  providedIn: 'any',
})
export class LoadMasterData {
  constructor(
    private masterDataRepository: MasterDataRepository,
    private masterDataService: MasterDataService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private taskService: TaskService
  ) {}

  async execute(): Promise<MasterData> {
    try {
      const masterData = await this.masterDataRepository.load();
      await this.masterDataService.save(masterData);
      await this.taskService.saveTasks(masterData.tasks);
      // 1a vegada, es posen els per defecte
      // posteriors, es resetegen date filtes
      await this.filtersService.updateFilters();
      this.eventsService.publishMasterDataUpdatedEvent(new MasterDataUpdatedEvent());
      return masterData;
    } catch (exception) {
      await this.masterDataService.markErrorOnDownload();
      throw exception; // propagate
    }
  }
}
