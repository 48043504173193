import { Injectable } from '@angular/core';
import { TaskRepository } from 'src/core/tasks/domain/task-repository';
import { CreateTaskRequest } from './create-task-request';
import { QueueService } from '../../../queue/domain/queue.service';
import { LocalTask } from '../../../master-data/domain/local-task';
import { CreateTaskQueueElement } from '../../../queue/domain/elements/create-task-queue-element';
import { TimeoutException } from '../../../common/domain/exceptions/timeout-exception';
import { NoConnectionException } from '../../../common/domain/exceptions/no-connection-exception';
import { TaskService } from '../../domain/task.service';
import { BadRequestException } from '../../../common/domain/exceptions/bad-request-exception';
import { InternalServerException } from '../../../common/domain/exceptions/internal-server-exception';
import { QueueElement, QueueElementError } from '../../../queue/domain/queue-element';

@Injectable({
  providedIn: 'any',
})
export class CreateTask {
  private task: LocalTask;
  private isCreatedToServer = false;
  private isRetry = false;

  constructor(
    private readonly taskRepository: TaskRepository,
    private taskService: TaskService,
    private queueService: QueueService
  ) {}

  async execute(request: CreateTaskRequest): Promise<void> {
    this.task = request.task;
    const queueElement = request.queueElement;
    this.isRetry = queueElement !== null;

    try {
      if (!this.isRetry) {
        await this.saveTaskIntoLocalDevice();
      }

      const payload = this.getPayloadFromTask();
      const response = await this.taskRepository.create(payload);
      if (this.task.isLocal()) {
        // update the stored task in collection with the new id
        await this.updateTaskIntoLocalDevice(response.taskId);
        // change every reference in the queue to this task_id to the new task_id
        await this.queueService.updateLocalTaskReferences(this.task.id, response.taskId);
      }
      this.isCreatedToServer = true;
    } catch (exception) {
      if (this.isConnectionProblem(exception)) {
        await this.enqueueOperation();
        throw exception; // propagate exception, since it must be handled differently
      }

      // exceptions during retrying do not enqueue or rollback the task, but store error to display in list
      if (this.isRetry) {
        // store the error in the enqueued element
        if (exception instanceof BadRequestException) {
          await this.updateQueueElementWithBadRequestException(exception, queueElement);
        }
        if (exception instanceof InternalServerException) {
          await this.updateQueueElementWithInternalServerException(queueElement);
        }
        throw exception; // propagate exception without doing anything else
      }

      throw exception; // propagate exception
    }
  }

  private getPayloadFromTask() {
    return {
      expectedDate: this.task.date.expected,
      createdAt: this.task.createdAt,
      ambitId: this.task.ambit.value.place.id,
      workTypeId: this.task.workType.id,
      subject: this.task.subject,
    };
  }

  private async updateQueueElementWithBadRequestException(
    exception: BadRequestException,
    queueElement: CreateTaskQueueElement
  ) {
    const errors = exception.getErrors();
    await this.updateQueueElementWithErrors(queueElement, errors);
  }

  private async updateQueueElementWithInternalServerException(queueElement: CreateTaskQueueElement) {
    const errors = [QueueElement.getInternalServerExceptionError()];
    await this.updateQueueElementWithErrors(queueElement, errors);
  }

  private async updateQueueElementWithErrors(queueElement: CreateTaskQueueElement, errors: Array<QueueElementError>) {
    const operationStatus = {
      isCreatedToServer: this.isCreatedToServer,
    };
    queueElement.update(this.task, operationStatus, errors);
    await this.queueService.updateQueueElement(queueElement);
  }

  private async saveTaskIntoLocalDevice() {
    await this.taskService.saveTask(this.task);
  }

  private isConnectionProblem(exception: any): boolean {
    return exception instanceof TimeoutException || exception instanceof NoConnectionException;
  }

  private async enqueueOperation(errors: Array<QueueElementError> = []) {
    const operationStatus = {
      isCreatedToServer: this.isCreatedToServer,
    };
    await this.queueService.enqueue(CreateTaskQueueElement.fromTask(this.task, operationStatus, errors));
  }

  private async updateTaskIntoLocalDevice(newTaskId: string) {
    await this.taskService.removeTask(this.task);
    this.task.changeId(newTaskId);
    await this.taskService.saveTask(this.task);
  }
}
