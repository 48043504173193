import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserLoggedInEvent } from './user-logged-in.event';
import { UserLoggedOutEvent } from './user-logged-out.event';
import { AuthorizationExpiredEvent } from './authorization-expired.event';
import { AmbitChangedEvent } from './ambit-changed.event';
import { MasterDataUpdatedEvent } from './master-data-updated.event';
import { CredentialsScannedEvent } from './credentials-scanned.event';
import { NumPendingElementsChangedEvent } from './num-pending-elements-changed.event';
import { IsSynchronizingEvent } from './is-synchronizing.event';
import { AppIsOutdatedEvent } from './app-is-outdated.event';
import { NewAppVersionAvailableEvent } from './new-app-version-available.event';
import { LocationScannedEvent } from './location-scanned.event';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private appIsOutdatedSubject: Subject<AppIsOutdatedEvent> = new Subject<AppIsOutdatedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  appIsOutdated$: Observable<AppIsOutdatedEvent> = this.appIsOutdatedSubject.asObservable();
  private newAppVersionAvailableSubject: Subject<NewAppVersionAvailableEvent> =
    new Subject<NewAppVersionAvailableEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  newAppVersionAvailable$: Observable<NewAppVersionAvailableEvent> = this.newAppVersionAvailableSubject.asObservable();
  private loggedInEventSubject: Subject<UserLoggedInEvent> = new Subject<UserLoggedInEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  userLoggedIn$: Observable<UserLoggedInEvent> = this.loggedInEventSubject.asObservable();
  private loggedOutEventSubject: Subject<UserLoggedOutEvent> = new Subject<UserLoggedOutEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  userLoggedOut$: Observable<UserLoggedOutEvent> = this.loggedOutEventSubject.asObservable();
  private authorizationExpiredEventSubject: Subject<AuthorizationExpiredEvent> =
    new Subject<AuthorizationExpiredEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  authorizationExpired$: Observable<AuthorizationExpiredEvent> = this.authorizationExpiredEventSubject.asObservable();
  private ambitChangedEventSubject: Subject<AmbitChangedEvent> = new Subject<AmbitChangedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ambitChanged$: Observable<AmbitChangedEvent> = this.ambitChangedEventSubject.asObservable();
  private masterDataUpdatedEventSubject: Subject<MasterDataUpdatedEvent> = new Subject<MasterDataUpdatedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  masterDataUpdated$: Observable<MasterDataUpdatedEvent> = this.masterDataUpdatedEventSubject.asObservable();
  private credentialsScannedEventSubject: Subject<CredentialsScannedEvent> = new Subject<CredentialsScannedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  credentialsScanned$: Observable<CredentialsScannedEvent> = this.credentialsScannedEventSubject.asObservable();
  private locationScannedEventSubject: Subject<LocationScannedEvent> = new Subject<LocationScannedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  locationScanned$: Observable<LocationScannedEvent> = this.locationScannedEventSubject.asObservable();
  private isSynchronizingEventSubject: Subject<IsSynchronizingEvent> = new Subject<IsSynchronizingEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  isSynchronizing$: Observable<IsSynchronizingEvent> = this.isSynchronizingEventSubject.asObservable();
  private numPendingElementsChangedEventSubject: Subject<NumPendingElementsChangedEvent> =
    new Subject<NumPendingElementsChangedEvent>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  numPendingElementsChanged$: Observable<NumPendingElementsChangedEvent> =
    this.numPendingElementsChangedEventSubject.asObservable();

  publishCredentialsScannedEvent(event: CredentialsScannedEvent): void {
    this.credentialsScannedEventSubject.next(event);
  }

  publishLocationScannedEvent(event: LocationScannedEvent): void {
    this.locationScannedEventSubject.next(event);
  }

  publishLoggedInEvent(event: UserLoggedInEvent): void {
    this.loggedInEventSubject.next(event);
  }

  publishLoggedOutEvent(event: UserLoggedOutEvent): void {
    this.loggedOutEventSubject.next(event);
  }

  publishAuthorizationExpired(event: AuthorizationExpiredEvent) {
    this.authorizationExpiredEventSubject.next(event);
  }

  publishAmbitChanged(event: AmbitChangedEvent) {
    this.ambitChangedEventSubject.next(event);
  }

  publishMasterDataUpdatedEvent(event: MasterDataUpdatedEvent): void {
    this.masterDataUpdatedEventSubject.next(event);
  }

  publishNumPendingElementsChanged(event: NumPendingElementsChangedEvent) {
    this.numPendingElementsChangedEventSubject.next(event);
  }

  publishIsSynchronizing(event: IsSynchronizingEvent) {
    this.isSynchronizingEventSubject.next(event);
  }

  publishAppIsOutdated(event: AppIsOutdatedEvent) {
    this.appIsOutdatedSubject.next(event);
  }

  publishNewAppVersionIsAvailable(event: NewAppVersionAvailableEvent) {
    this.newAppVersionAvailableSubject.next(event);
  }
}
