import { Task } from 'src/core/master-data/domain/task';
import { Nullable } from '../../../common/domain/types/types';
import { ConfirmCloseTaskQueueElement } from '../../../queue/domain/elements/confirm-close-task-queue-element';

export class ConfirmCloseTaskRequest {
  constructor(public readonly task: Task, public readonly queueElement: Nullable<ConfirmCloseTaskQueueElement>) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: ConfirmCloseTaskQueueElement) {
    return new this(element.task, element);
  }
}
