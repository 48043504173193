import { LocalDate } from 'src/core/common/domain/date/local-date';
import { Task, TaskPrimitives } from 'src/core/master-data/domain/task';
import { UuidGenerator } from '../../../common/infrastructure/uuid/uuid-generator';
import { TaskQueueElement } from './task-queue-element';
import { QueueElementError, QueueElementType } from '../queue-element';

type ConfirmCloseTaskQueueElementPrimitives = {
  id: string;
  created_at: string;
  type: string;
  task: TaskPrimitives;
  operation_status: {
    isSavedToServer: boolean;
    isConfirmClosedToServer: boolean;
  };
  errors: Array<QueueElementError>;
};

export class ConfirmCloseTaskQueueElement extends TaskQueueElement {
  constructor(
    id: string,
    createdAt: LocalDate,
    public task: Task,
    public operationStatus: {
      isSavedToServer: boolean;
      isConfirmClosedToServer: boolean;
    },
    public errors: Array<QueueElementError>
  ) {
    super(id, createdAt, QueueElementType.CONFIRM_CLOSE);
    this.label = this.task.subject;
    this.publicId = this.task.id;
  }

  static fromPayload(payload: ConfirmCloseTaskQueueElementPrimitives) {
    return new this(
      payload.id,
      LocalDate.fromIsoString(payload.created_at),
      Task.fromPrimitives(payload.task),
      payload.operation_status,
      payload.errors
    );
  }

  static fromTask(
    task: Task,
    operationStatus: { isSavedToServer: boolean; isConfirmClosedToServer: boolean },
    errors: Array<QueueElementError>
  ) {
    return new this(UuidGenerator.random(), LocalDate.now(), task, operationStatus, errors);
  }

  toPrimitives(): ConfirmCloseTaskQueueElementPrimitives {
    return {
      id: this.id,
      type: this.type,
      created_at: this.createdAt.atom,
      task: this.task.toPrimitives(),
      operation_status: this.operationStatus,
      errors: this.errors,
    };
  }

  update(
    task: Task,
    operationStatus: { isConfirmClosedToServer: boolean; isSavedToServer: boolean },
    errors: Array<QueueElementError>
  ) {
    this.task = task;
    this.operationStatus = operationStatus;
    this.errors = errors;
  }
}
