export type TaskAmbitData = {
  printable: string;
  service_address: {
    id: string;
    name: string;
  };
  intermediate_levels: Array<{
    id: string;
    name: string;
    order: number;
  }>;
  place: {
    id: string;
    name: string;
    code: string[];
  };
};

export class TaskAmbit {
  constructor(public readonly value: TaskAmbitData) {}

  static fromPrimitives(primitives: TaskAmbitData) {
    // TODO: Remove this ACL when it applies (it ensures place.code is an array instead of a string)
    if (typeof primitives.place.code === 'string') {
      primitives.place.code = [primitives.place.code];
    }
    return new TaskAmbit(primitives);
  }

  toPrimitives(): TaskAmbitData {
    return this.value;
  }

  getServiceAddressName(): string {
    return this.value.service_address.name;
  }

  printableAmbit(): string {
    return this.value.printable;
  }

  pathIds(): string[] {
    const pathIds: string[] = [];
    pathIds.push(this.value.place.id);
    pathIds.push(...this.value.intermediate_levels.map((i) => i.id));
    pathIds.push(this.value.service_address.id);
    return pathIds;
  }
}
