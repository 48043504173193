import { Injectable } from '@angular/core';
import { HttpClient } from 'src/core/common/domain/http/http-client';
import { Location, Task } from 'src/core/master-data/domain/task';
import { TaskRepository } from 'src/core/tasks/domain/task-repository';
import { LocalDate } from 'src/core/common/domain/date/local-date';
import { FilesystemService } from 'src/core/filesystem/domain/filesystem.service';
import { LocalAttachment } from 'src/core/attachment/domain/local-attachment';
import { LocalFileAttachment } from 'src/core/attachment/domain/local-file-attachment';
import { Attachment } from 'src/core/attachment/domain/attachment';
import { Nullable } from 'src/core/common/domain/types/types';
import { ApiService } from 'src/core/api/domain/api.service';
import { ExceptionManagerService } from 'src/core/common/domain/exceptions/exception-manager.service';
import { ApiAccessService } from '../../common/domain/api-access/api-access.service';
import { ApiSaveTaskAttachmentRequest, ApiSaveTaskRequest } from './api-save-task-request';
import { ApiCloseTaskRequest } from './api-close-task-request';
import { AttachmentHelper } from '../../../tests/core/common/domain/attachment/attachment.helper';
import { ApiRejectTaskRequest } from './api-reject-task-request';
import { ApiCreateTaskRequest } from './api-create-task-request';
import { MasterDataService } from '../../master-data/domain/master-data-service';
import { RequestTooLargeException } from '../../common/domain/exceptions/request-too-large-exception';
import { UnitHelper } from '../../common/domain/units/unit-helper';
import { Translation } from '../../common/domain/translation/translation';
import { MasterDataConfiguration } from '../../master-data/domain/master-data-configuration';
import { ApiTaskResponse } from '../../master-data/infrastructure/api-task-response';
import { ConnectivityService } from '../../connection/domain/connectivity.service';
import { NoConnectionException } from '../../common/domain/exceptions/no-connection-exception';
import { ApiTasksLoadResponse } from '../../master-data/infrastructure/api-tasks-load-response';
import { GeolocationHelper } from '../../common/domain/geolocation/geolocation-helper';

@Injectable({
  providedIn: 'any',
})
export class HttpTaskRepository extends TaskRepository {
  constructor(
    private readonly apiAccessService: ApiAccessService,
    private readonly apiService: ApiService,
    private readonly client: HttpClient,
    private readonly connectivityService: ConnectivityService,
    private readonly exceptionManager: ExceptionManagerService,
    private readonly filesystem: FilesystemService,
    private readonly masterData: MasterDataService,
    private readonly masterDataService: MasterDataService,
    private readonly translation: Translation
  ) {
    super();
  }

  load(ambitCode: string = null): Promise<Array<Task>> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const parameters = {
          ambit_code: ambitCode,
          // date_from: LocalDate.fromString('2024-02-01').ymd, // 2010-01-01
          // date_to: LocalDate.fromString('2024-02-29').ymd, // 2090-01-01
          // search: undefined,
          // work_types: undefined,
          // status_list: undefined,
          // assignee: undefined,
          // page: 1,
          // page_size: 100_000,
        };
        // remove empty parameters (whose value is undefined and null)
        const filteredParameters = Object.fromEntries(
          Object.entries(parameters).filter(
            ([key, value]) => key !== undefined && value !== undefined && value !== null
          )
        );

        const queryString = new URLSearchParams(filteredParameters).toString();
        const url = `${this.apiService.baseUrl()}/tasks.list?${queryString}`;
        const response = await this.client.get<ApiTasksLoadResponse>(url, {}, {}, true, true);

        const tasks: Array<Task> = this.buildTasksFromApiResponse(
          response.tasks,
          this.masterDataService.getConfiguration()
        );
        resolve(tasks);
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async start(task: Task): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const payload = {
          id: task.id,
          started_at: LocalDate.now().atom, // it should be the task started date (from Task class)
        };
        await this.client.post(`${this.apiService.baseUrl()}/task.start`, payload, {}, true, true);
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async stop(task: Task): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const payload = {
          id: task.id,
          stopped_at: LocalDate.now().atom, // it should be the task started date (from Task class)
        };
        await this.client.post(`${this.apiService.baseUrl()}/task.stop`, payload, {}, true, true);
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async close(task: Task): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const extraData = task.getCloseTaskData();
        const signatureAttachmentContent = extraData?.signature
          ? AttachmentHelper.removeBase64Header((await this.getAttachmentContent(extraData?.signature)).base64_content)
          : null;
        const payload: ApiCloseTaskRequest = {
          id: task.id,
          closed_at: task.closedAt?.atom ?? null,
          emails: extraData?.emails ?? null,
          predefined_observations: extraData?.predefinedObservations ?? null,
          resolution: extraData?.resolutions ?? null,
          signature_type: extraData?.signatureType ?? null,
          signature: signatureAttachmentContent,
          observations_for_client: extraData?.observations ?? null,
        };
        await this.ensureMaxRequestSizeIsNotTooLarge(JSON.stringify(payload));
        await this.client.post(`${this.apiService.baseUrl()}/task.close`, payload, {}, true, true);
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async reject(task: Task): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const extraData = task.getRejectTaskData();
        const payload: ApiRejectTaskRequest = {
          id: task.id,
          rejected_at: task.rejectedAt?.atom ?? null,
          predefined_observations: extraData?.predefinedObservations ?? null,
          resolution: extraData?.resolutions ?? null,
          observations_for_client: extraData?.observations ?? null,
        };
        await this.client.post(`${this.apiService.baseUrl()}/task.reject`, payload, {}, true, true);
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async create(data: {
    createdAt: LocalDate;
    expectedDate: LocalDate;
    ambitId: string;
    workTypeId: string;
    subject: string;
  }): Promise<{ taskId: string }> {
    return new Promise<{ taskId: string }>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();

        // If expected date is today, we send the current time, otherwise we send at start_of_day (00:00:00)
        const expectedDate = data.expectedDate.ymd === data.createdAt.ymd
          ? data.createdAt.atom
          : data.expectedDate.atom;

        const payload: ApiCreateTaskRequest = {
          expected_date: expectedDate,
          created_at: data.createdAt.atom,
          ambit_id: data.ambitId,
          work_type_id: data.workTypeId,
          subject: data.subject,
        };
        const response = await this.client.post<{
          task_id: string;
        }>(`${this.apiService.baseUrl()}/task.create`, payload, {}, true, true);
        const taskId = response.task_id;
        resolve({ taskId });
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async save(task: Task): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // we don't save if we have no attachments
        if (task.attachments.length === 0) {
          resolve();
          return;
        }

        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const attachments: Array<ApiSaveTaskAttachmentRequest> = await this.getAttachmentsContent(task);
        const payload: ApiSaveTaskRequest = {
          id: task.id,
          updated_at: task.updatedAt?.atom ?? LocalDate.now().atom,
          attachments,
        };
        await this.ensureMaxRequestSizeIsNotTooLarge(JSON.stringify(payload));
        await this.client.post(`${this.apiService.baseUrl()}/task.save`, payload, {}, true, true);
        resolve();
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private buildTasksFromApiResponse(
    tasks: Array<ApiTaskResponse>,
    configuration: MasterDataConfiguration
  ): Array<Task> {
    return tasks.map((task) => this.buildTaskFromApiResponse(task, configuration));
  }

  private buildTaskFromApiResponse(task: ApiTaskResponse, configuration: MasterDataConfiguration) {
    const taskDate = {
      expected: task.planned_date.expected,
      from: task.planned_date.valid_range_start,
      to: task.planned_date.valid_range_end,
    };
    const taskLocation: Location = {
      full_address: task.location.full_address,
      geolocation: GeolocationHelper.parseFromAPI(task.location),
    };

    // API fallback (to prevent lack of field "work_type.name")
    this.applyWorkTypeNameApiFallback(task.work_type);

    const workType = {
      id: task.work_type.id,
      name: task.work_type.name,
    };
    const displayServiceAddressInTask = configuration.isDisplayServiceAddressInTask();
    const displayPostalAddressInImmediateTask = configuration.isDisplayPostalAddressInImmediateTask();
    const displayPostalAddressInPlannedTask = configuration.isDisplayPostalAddressInPlannedTask();
    const displayAmbitInImmediateTask = configuration.isDisplayAmbitInImmediateTask();
    const displayAmbitInPlannedTask = configuration.isDisplayAmbitInPlannedTask();
    const displayDescriptionInImmediateTask = configuration.isDisplayDescriptionInImmediateTask();
    const displayDescriptionInPlannedTask = configuration.isDisplayDescriptionInPlannedTask();

    return Task.fromPrimitives({
      id: task.id,
      status: task.status,
      subject: task.subject,
      description: task.description,
      work_type: workType,
      ambit: task.ambit,
      date: taskDate,
      assignee: task.assignee,
      frequency: task.frequency,
      must_be_modified_by_assignee: task.must_be_modified_by_assignee,
      location: taskLocation,
      started_at: task.started_at,
      updated_at: task.updated_at,
      closed_at: task.closed_at,
      rejected_at: null,
      created_at: task.created_at,
      attachments: [],
      display_service_address_in_task: displayServiceAddressInTask,
      display_postal_address_in_immediate_task: displayPostalAddressInImmediateTask,
      display_postal_address_in_planned_task: displayPostalAddressInPlannedTask,
      display_ambit_in_immediate_task: displayAmbitInImmediateTask,
      display_ambit_in_planned_task: displayAmbitInPlannedTask,
      display_description_in_immediate_task: displayDescriptionInImmediateTask,
      display_description_in_planned_task: displayDescriptionInPlannedTask,
      close_task_data: null,
      reject_task_data: null,
    });
  }

  // TODO: Extract duplicated method or delete
  private applyWorkTypeNameApiFallback(workType: { id: string; name?: string }) {
    if (!workType.name) {
      const key = `FILTERS.WORK_TYPES.${workType.id.toUpperCase()}`;
      let workTypeName = this.translation.instant(key);
      if (workTypeName === key) {
        workTypeName = workType.id;
      }
      workType.name = workTypeName;
    }
  }

  private async getAttachmentsContent(task: Task): Promise<Array<ApiSaveTaskAttachmentRequest>> {
    const attachments: Array<ApiSaveTaskAttachmentRequest> = [];

    for (const attachment of task.attachments) {
      const attachmentContent = await this.getAttachmentContent(attachment);
      if (attachmentContent) {
        attachments.push({
          filename: attachment.filename,
          base64_content: AttachmentHelper.removeBase64Header(attachmentContent.base64_content),
        });
      }
    }

    return attachments;
  }

  private async getAttachmentContent(
    attachment: Attachment
  ): Promise<Nullable<{ id: string; filename: string; base64_content: string }>> {
    let base64Content = null;
    if (attachment instanceof LocalAttachment) {
      base64Content = attachment.content;
    } else if (attachment instanceof LocalFileAttachment) {
      base64Content = await this.filesystem.readAsDataURL(attachment.getUrl());
    }
    if (base64Content === null) {
      return null;
    }

    return {
      id: attachment.id,
      filename: attachment.filename,
      base64_content: base64Content,
    };
  }

  private async ensureMaxRequestSizeIsNotTooLarge(payload: string): Promise<void> {
    const requestSize = payload.length;
    // …output bytes per input byte converges to 4 / 3 or 1.33333 for large n
    // E.g.: A request of 7 MB will actually weigh 9.333 MB
    //       7 MB * 4/3  = 9.333 MB
    const base64EncodeFactor = 4 / 3;
    const encodedRequestSize = requestSize * base64EncodeFactor;
    const maxRequestSize = this.masterData.getConfiguration().getMaxRequestSize();

    if (encodedRequestSize > maxRequestSize) {
      const encodedRequestSizeHumanReadable = UnitHelper.humanReadable(encodedRequestSize);
      const maxRequestSizeHumanReadable = UnitHelper.humanReadable(maxRequestSize);

      throw new RequestTooLargeException(
        this.translation.instant('MESSAGES.REQUEST_TOO_LARGE', {
          request_size: encodedRequestSizeHumanReadable,
          max_request_size: maxRequestSizeHumanReadable,
        })
      );
    }
  }
}
