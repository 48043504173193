import { LocalDate } from 'src/core/common/domain/date/local-date';

export enum QueueElementType {
  START = 'start',
  STOP = 'stop',
  CLOSE = 'close',
  CONFIRM_CLOSE = 'confirm_close',
  REJECT = 'reject',
  CONFIRM_REJECT = 'confirm_reject',
  CREATE = 'create',
}

export type QueueElementError = {
  parameter: string;
  reason: string;
  message: string;
};

export abstract class QueueElement {
  label: string;
  publicId: string;
  errors: Array<QueueElementError>;

  protected constructor(
    public readonly id: string,
    public readonly createdAt: LocalDate,
    public readonly type: QueueElementType
  ) {}

  static fromPayload(payload: any) {
    throw new Error('You need to implement fromPayload');
  }

  static getInternalServerExceptionError(): QueueElementError {
    return {
      parameter: 'none',
      reason: '500',
      message: 'Internal Server Exception',
    };
  }

  abstract toPrimitives(): any;

  hasServerError(): boolean {
    return this.errors.length === 1 && this.errors[0].reason === '500';
  }

  hasErrors(): boolean {
    return !this.hasServerError() && this.errors.length > 0;
  }
}
