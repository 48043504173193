import { Task } from 'src/core/master-data/domain/task';
import { LocalDate } from '../../common/domain/date/local-date';

export abstract class TaskRepository {
  abstract load(ambitCode: string): Promise<Array<Task>>;

  abstract save(task: Task): Promise<void>;

  abstract start(task: Task): Promise<void>;

  abstract stop(task: Task): Promise<void>;

  abstract close(task: Task): Promise<void>;

  abstract confirmClose(task: Task): Promise<void>;

  abstract reject(task: Task): Promise<void>;

  abstract confirmReject(task: Task): Promise<void>;

  abstract create(data: {
    createdAt: LocalDate;
    expectedDate: LocalDate;
    ambitId: string;
    workTypeId: string;
    subject: string;
  }): Promise<{
    taskId: string;
  }>;
}
