import { Injectable } from '@angular/core';
import { TaskRepository } from 'src/core/tasks/domain/task-repository';
import { TaskService } from '../../domain/task.service';
import { StartTaskRequest } from './start-task-request';
import { Task } from '../../../master-data/domain/task';
import { TimeoutException } from '../../../common/domain/exceptions/timeout-exception';
import { NoConnectionException } from '../../../common/domain/exceptions/no-connection-exception';
import { QueueService } from '../../../queue/domain/queue.service';
import { StartTaskQueueElement } from '../../../queue/domain/elements/start-task-queue-element';
import { InternalServerException } from 'src/core/common/domain/exceptions/internal-server-exception';
import { QueueElement, QueueElementError } from '../../../queue/domain/queue-element';

@Injectable({
  providedIn: 'any',
})
export class StartTask {
  private isSavedToServer = false;
  private isStartedToServer = false;
  private isRetry = false;
  private task: Task;

  constructor(
    private readonly taskRepository: TaskRepository,
    private readonly taskService: TaskService,
    private readonly queueService: QueueService
  ) {}

  async execute(request: StartTaskRequest): Promise<void> {
    this.task = request.task;
    const queueElement = request.queueElement;
    this.isRetry = queueElement !== null;
    this.isSavedToServer = queueElement !== null ? queueElement.operationStatus.isSavedToServer : false;
    this.isStartedToServer = queueElement !== null ? queueElement.operationStatus.isStartedToServer : false;

    try {
      if (!this.isRetry) {
        this.task.start();
        await this.saveTaskIntoLocalDevice();
      }

      const taskIsInQueue = this.queueService.hasQueueElementWithTaskId(this.task.id);
      if (taskIsInQueue && !this.isRetry) {
        await this.enqueueOperation();
        return;
      }

      await this.saveTaskToExternalServer(this.task);
      await this.startTaskToExternalServer(this.task);
    } catch (exception) {
      if (this.isConnectionProblem(exception)) {
        await this.enqueueOperation();
        return;
      }

      // exceptions during retrying do not enqueue or rollback the task, but store error to display in list
      if (this.isRetry) {
        // store the error in the enqueued element
        if (exception instanceof InternalServerException) {
          await this.updateQueueElementWithInternalServerException(queueElement);
        }
        throw exception; // propagate exception without doing anything else
      }

      // otherwise, rollback
      this.task.rollback();
      await this.saveTaskIntoLocalDevice();
      throw exception; // propagate exception
    }
  }

  private async saveTaskIntoLocalDevice() {
    await this.taskService.saveTask(this.task);
  }

  private async saveTaskToExternalServer(task: Task) {
    if (this.isSavedToServer) {
      return;
    }
    await this.taskRepository.save(task);
    this.isSavedToServer = true;
  }

  private async startTaskToExternalServer(task: Task) {
    if (this.isStartedToServer) {
      return;
    }
    await this.taskRepository.start(task);
    this.isStartedToServer = true;
  }

  private isConnectionProblem(exception: any): boolean {
    return exception instanceof TimeoutException || exception instanceof NoConnectionException;
  }

  private async enqueueOperation(errors: Array<QueueElementError> = []) {
    const operationStatus = {
      isSavedToServer: this.isSavedToServer,
      isStartedToServer: this.isStartedToServer,
    };
    await this.queueService.enqueue(StartTaskQueueElement.fromTask(this.task, operationStatus, errors));
  }

  private async updateQueueElementWithInternalServerException(queueElement: StartTaskQueueElement) {
    const errors = [QueueElement.getInternalServerExceptionError()];
    await this.updateQueueElementWithErrors(queueElement, errors);
  }

  private async updateQueueElementWithErrors(queueElement: StartTaskQueueElement, errors: Array<QueueElementError>) {
    const operationStatus = {
      isSavedToServer: this.isSavedToServer,
      isStartedToServer: this.isStartedToServer,
    };
    queueElement.update(this.task, operationStatus, errors);
    await this.queueService.updateQueueElement(queueElement);
  }
}
