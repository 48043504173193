import { Storage } from 'src/core/storage/domain/storage';
import { Injectable } from '@angular/core';
import { StorableService } from 'src/core/storage/domain/storable-service';
import { MasterDataService } from 'src/core/master-data/domain/master-data-service';
import { Initializable } from 'src/core/common/domain/initializer/initializable';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from 'src/core/common/domain/types/types';
import { Assignee } from '../../master-data/domain/filters';
import { ArrayHelper } from '../../common/domain/array/array-helper';

export type TasksFilters = {
  workType: string[];
  status: string[];
  assigned: string[];
  date: {
    id: string;
    from: string;
    to: string;
  };
  customRange: {
    from: string;
    to: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class FiltersService implements StorableService, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private _filters: Nullable<TasksFilters> = null;
  private readonly KEY_FILTERS = 'filters';

  constructor(private storage: Storage, private masterDataService: MasterDataService) {}

  async init(): Promise<void> {
    const filters = await this.storage.get(this.KEY_FILTERS);
    if (filters !== null) {
      // TODO: Convert object from primitives, check format
      if (filters && !filters.customRange) {
        filters.customRange = {
          from: filters.date.from,
          to: filters.date.to,
        };
      }
      this._filters = filters as TasksFilters;
    }

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    this._filters = null;
    await this.storage.remove(this.KEY_FILTERS);
  }

  async updateFilters() {
    const taskFilters = this.get();
    if (!taskFilters) {
      console.warn('Updating filters: setting filters by default (first download)');
      // if filters are still empty, set default ones
      const defaultTaskFilters = this.masterDataService.getDefaultTasksFilters();
      await this.save(defaultTaskFilters);
      return;
    }

    const dateFilter = this.masterDataService.calculateDateFilterFromDateId(
      taskFilters.date.id,
      taskFilters.customRange
    );

    // check if all selected filters still exist in master data
    if (!this.currentMasterDataIsCompatibleWithCurrentFilters()) {
      console.warn('Updating filters: setting filters by default (current filters are incompatible with master data)');
      const defaultTaskFilters = this.masterDataService.getDefaultTasksFilters();
      // TODO: Reset filters but maintain date filter??
      defaultTaskFilters.date = dateFilter.date;
      defaultTaskFilters.customRange = dateFilter.customRange;
      await this.save(defaultTaskFilters);
      return;
    }

    console.warn('Updating filters: updating only date filters');
    // otherwise reset only date filters
    taskFilters.date = dateFilter.date;
    taskFilters.customRange = dateFilter.customRange;

    await this.save(taskFilters);
  }

  get(): Nullable<TasksFilters> {
    // TODO: Valorar si null es valor valido o si en el init deberiamos poner un valor por defecto
    return this._filters;
  }

  async save(filters: TasksFilters): Promise<void> {
    this._filters = filters;
    // To primitives before
    await this.storage.set(this.KEY_FILTERS, filters);
  }

  async saveCustomRange(customRange: { from: string; to: string }) {
    this._filters.customRange = customRange;
    await this.save(this._filters);
  }

  async resetForNonStoredAmbit() {
    /**
     * Date, Work type and Status filters: remain unchanged, to respect what is indicated by the API or selected by the user.
     * Assignment filter: all options are selected so that the tasks not assigned to the user are visible.
     */
    const allFilters = this.masterDataService.getFilters();
    const newFilters = this._filters;
    newFilters.assigned = allFilters.assignees.map((a: Assignee) => a);
    await this.save(newFilters);
  }

  private currentMasterDataIsCompatibleWithCurrentFilters() {
    const currentMasterDataFilters = this.masterDataService.getFilters();
    const currentFilters = this.get();
    // check all selected "work types" still exist in master data
    const availableWorkTypes = currentMasterDataFilters.workTypes.map((workType) => workType.id);
    if (!ArrayHelper.containsAll(currentFilters.workType, availableWorkTypes)) {
      return false;
    }
    // check all selected "status" still exist in master data
    const availableStatuses = currentMasterDataFilters.statuses.map((status) => status.value);
    if (!ArrayHelper.containsAll(currentFilters.status, availableStatuses)) {
      return false;
    }
    // check all selected "assignee" still exist in master data
    const availableAssignees = currentMasterDataFilters.assignees.map((assignee) => assignee as string);
    if (!ArrayHelper.containsAll(currentFilters.assigned, availableAssignees)) {
      return false;
    }

    return true;
  }
}
