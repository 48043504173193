export enum ConfirmRejectTaskActionsShow {
  ALWAYS = 'always',
  NEVER = 'never',
}

export type ConfirmRejectTaskActionsData = {
  show: ConfirmRejectTaskActionsShow;
  fields: {
    resolutions: {
      show: boolean;
      show_in_bulk: boolean;
      options: Array<{
        id: string;
        name: string;
        isFinal: boolean;
      }>;
    };
  };
};

export class ConfirmRejectTaskActions {
  constructor(public readonly value: ConfirmRejectTaskActionsData) {}

  shouldShowModal() {
    if (this.value.show === ConfirmRejectTaskActionsShow.ALWAYS) {
      return true;
    }

    if (this.value.show === ConfirmRejectTaskActionsShow.NEVER) {
      return false;
    }

    // otherwise
    return false;
  }

  shouldShowResolutions(bulkMode: boolean = false): boolean {
    if (!this.value.fields.resolutions) {
      return false;
    }

    if (bulkMode) {
      return this.value.fields.resolutions.show_in_bulk;
    }

    return this.value.fields.resolutions.show;
  }

  optionsForResolutions(): Array<{ id: string; label: string }> {
    const allOptions = this.value.fields.resolutions.options;

    return allOptions.map((o) => ({
      id: o.id,
      label: o.name,
    }));
  }
}
