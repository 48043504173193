import { Task } from 'src/core/master-data/domain/task';
import { ConfirmRejectTaskQueueElement } from '../../../queue/domain/elements/confirm-reject-task-queue-element';

export class ConfirmRejectTaskRequest {
  constructor(public readonly task: Task, public readonly queueElement: ConfirmRejectTaskQueueElement) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: ConfirmRejectTaskQueueElement) {
    return new this(element.task, element);
  }
}
