import { Storage } from '../../storage/domain/storage';
import { Injectable } from '@angular/core';
import { StorableService } from '../../storage/domain/storable-service';
import { Initializable } from '../../common/domain/initializer/initializable';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from '../../common/domain/types/types';
import { Task, TaskPrimitives } from 'src/core/master-data/domain/task';

@Injectable({
  providedIn: 'root',
})
export class TaskService implements StorableService, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_TASKS = 'tasks';
  private readonly KEY_TASKS_FOR_NON_STORED_AMBIT = 'tasks_for_non_stored_ambit';
  private tasks: Nullable<Array<Task>> = null;
  private tasksForNonStoredAmbit: Nullable<Array<Task>> = null;

  constructor(private storage: Storage) {}

  async init(): Promise<void> {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    await this.clearTasksForNonStoredAmbit();
    await this.clearTasks();
  }

  async clearTasksForNonStoredAmbit() {
    this.tasksForNonStoredAmbit = null;
    await this.storage.remove(this.KEY_TASKS_FOR_NON_STORED_AMBIT);
  }

  getTasks(): Nullable<Array<Task>> {
    if (this.tasksForNonStoredAmbit !== null) {
      return this.tasksForNonStoredAmbit;
    }

    return this.tasks;
  }

  async saveTasks(tasks: Array<Task>): Promise<void> {
    this.tasks = tasks;
    const taskPrimitives = tasks.map((task) => task.toPrimitives());
    await this.storage.set(this.KEY_TASKS, taskPrimitives);
  }

  async saveTasksForNonStoredAmbit(tasks: Array<Task>) {
    this.tasksForNonStoredAmbit = tasks;
    const taskPrimitives = tasks.map((task) => task.toPrimitives());
    await this.storage.set(this.KEY_TASKS_FOR_NON_STORED_AMBIT, taskPrimitives);
  }

  async removeTask(task: Task): Promise<void> {
    if (this.hasNonStoredAmbitTasks()) {
      await this.removeTaskInNonStoredAmbitTaskCollection(task);
    } else {
      await this.removeTaskInTaskCollection(task);
    }
  }

  async saveTask(task: Task): Promise<void> {
    if (this.hasNonStoredAmbitTasks()) {
      await this.saveTaskInNonStoredAmbitTaskCollection(task);
    } else {
      await this.saveTaskInTaskCollection(task);
    }
  }

  hasNonStoredAmbitTasks(): boolean {
    return this.tasksForNonStoredAmbit !== null;
  }

  private async saveTaskInTaskCollection(task: Task): Promise<void> {
    const taskId = task.id;

    // Update Task object in storage (working with JSON to improve performance, saving us the computational cost
    // of transforming JSON into Task objects and vice versa)
    const storedTasks = (await this.storage.get(this.KEY_TASKS)) ?? [];
    const storedTaskIndex = storedTasks.findIndex((t: { id: string }) => t.id === taskId);
    if (storedTaskIndex !== -1) {
      storedTasks[storedTaskIndex] = task.toPrimitives();
      // update stored task list
      await this.storage.set(this.KEY_TASKS, storedTasks);
    } else {
      // not found, new
      storedTasks.push(task.toPrimitives());
      await this.storage.set(this.KEY_TASKS, storedTasks);
    }

    // update object in memory (work with Task objects)
    const inMemoryTasks = this.tasks ?? [];
    const inMemoryTaskIndex = inMemoryTasks.findIndex((t: Task) => t.id === taskId);
    if (this.tasks === null) {
      this.tasks = [];
    }

    if (inMemoryTaskIndex !== -1) {
      this.tasks[inMemoryTaskIndex] = task;
    } else {
      // not found, new
      this.tasks.push(task);
    }
  }

  private async saveTaskInNonStoredAmbitTaskCollection(task: Task): Promise<void> {
    const taskId = task.id;

    // Update Task object in storage (working with JSON to improve performance, saving us the computational cost
    // of transforming JSON into Task objects and vice versa)
    const storedTasks = (await this.storage.get(this.KEY_TASKS_FOR_NON_STORED_AMBIT)) ?? [];
    const storedTaskIndex = storedTasks.findIndex((t: { id: string }) => t.id === taskId);
    if (storedTaskIndex !== -1) {
      storedTasks[storedTaskIndex] = task.toPrimitives();
      // update stored task list
      await this.storage.set(this.KEY_TASKS_FOR_NON_STORED_AMBIT, storedTasks);
    } else {
      // not found, new
      storedTasks.push(task.toPrimitives());
      await this.storage.set(this.KEY_TASKS_FOR_NON_STORED_AMBIT, storedTasks);
    }

    // update object in memory (work with Task objects)
    const inMemoryTasks = this.tasksForNonStoredAmbit ?? [];
    const inMemoryTaskIndex = inMemoryTasks.findIndex((t: Task) => t.id === taskId);
    if (inMemoryTaskIndex !== -1) {
      this.tasksForNonStoredAmbit[inMemoryTaskIndex] = task;
    } else {
      // not found, new
      this.tasksForNonStoredAmbit.push(task);
    }
  }

  private async removeTaskInTaskCollection(task: Task): Promise<void> {
    const taskId = task.id;

    // Update Task object in storage (working with JSON to improve performance, saving us the computational cost
    // of transforming JSON into Task objects and vice versa)
    const storedTasks = (await this.storage.get(this.KEY_TASKS)) ?? [];
    const storedTaskIndex = storedTasks.findIndex((t: { id: string }) => t.id === taskId);
    if (storedTaskIndex !== -1) {
      // remove task from stored task list
      storedTasks.splice(storedTaskIndex, 1);
      await this.storage.set(this.KEY_TASKS, storedTasks);
    }

    // update object in memory (work with Task objects)
    const inMemoryTasks = this.tasks ?? [];
    const inMemoryTaskIndex = inMemoryTasks.findIndex((t: Task) => t.id === taskId);
    if (inMemoryTaskIndex !== -1) {
      // remove task from in memory task list
      inMemoryTasks.splice(inMemoryTaskIndex, 1);
    }
  }

  private async removeTaskInNonStoredAmbitTaskCollection(task: Task): Promise<void> {
    const taskId = task.id;

    // Update Task object in storage (working with JSON to improve performance, saving us the computational cost
    // of transforming JSON into Task objects and vice versa)
    const storedTasks = (await this.storage.get(this.KEY_TASKS_FOR_NON_STORED_AMBIT)) ?? [];
    const storedTaskIndex = storedTasks.findIndex((t: { id: string }) => t.id === taskId);
    if (storedTaskIndex !== -1) {
      // remove task from stored task list
      storedTasks.splice(storedTaskIndex, 1);
      await this.storage.set(this.KEY_TASKS_FOR_NON_STORED_AMBIT, storedTasks);
    }

    // update object in memory (work with Task objects)
    const inMemoryTasks = this.tasksForNonStoredAmbit ?? [];
    const inMemoryTaskIndex = inMemoryTasks.findIndex((t: Task) => t.id === taskId);
    if (inMemoryTaskIndex !== -1) {
      // remove task from in memory task list
      inMemoryTasks.splice(inMemoryTaskIndex, 1);
    }
  }

  private async clearTasks() {
    this.tasks = null;
    await this.storage.remove(this.KEY_TASKS);
  }

  private async initFromStorage(): Promise<void> {
    await this.initTasks();
    await this.initTasksForNonStoredAmbit();
  }

  private async initTasks() {
    const tasks = await this.storage.get(this.KEY_TASKS);
    this.tasks = tasks ? tasks.map((task: TaskPrimitives) => Task.fromPrimitives(task)) : null;
  }

  private async initTasksForNonStoredAmbit() {
    const tasksForNonStoredAmbit = await this.storage.get(this.KEY_TASKS_FOR_NON_STORED_AMBIT);
    this.tasksForNonStoredAmbit = tasksForNonStoredAmbit
      ? tasksForNonStoredAmbit.map((task: TaskPrimitives) => Task.fromPrimitives(task))
      : null;
  }
}
