import { Injectable } from '@angular/core';
import { SessionService } from 'src/core/session/domain/session.service';
import { MasterDataService } from '../../../master-data/domain/master-data-service';
import { FiltersService } from '../../../filters/domain/filters-service';
import { QueueService } from '../../../queue/domain/queue.service';
import { TaskService } from '../../../tasks/domain/task.service';
import { ApiService } from '../../../api/domain/api.service';
import { SystemService } from '../../../system/system.service';

@Injectable({
  providedIn: 'any',
})
export class Logout {
  constructor(
    private readonly apiService: ApiService,
    private readonly filtersService: FiltersService,
    private readonly masterData: MasterDataService,
    private readonly queueService: QueueService,
    private readonly session: SessionService,
    private readonly taskService: TaskService,
    private readonly systemService: SystemService,
  ) {}

  async execute(): Promise<void> {
    await this.apiService.clear();
    await this.filtersService.clear();
    await this.queueService.clear();
    await this.session.clear();
    await this.taskService.clear();
    await this.systemService.clearSessionRelatedData();
    await this.masterData.clear();
  }
}
