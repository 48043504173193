import { Task } from './task';
import { LocalDate } from '../../common/domain/date/local-date';
import { TaskDate } from './task-date';

export enum CloseTaskActionsShow {
  ALWAYS = 'always',
  NEVER = 'never',
  OUTSIDE_VALID_DATE_RANGE = 'outside-valid-date-range',
}

export type CloseTaskActionsData = {
  show: CloseTaskActionsShow;
  fields: {
    predefined_observations: {
      show: boolean;
      show_in_bulk: boolean;
      options: Array<{
        id: string;
        name: string;
        condition: string;
      }>;
    };
    resolutions: {
      show: boolean;
      show_in_bulk: boolean;
      options: Array<{
        id: string;
        name: string;
        isFinal: boolean;
      }>;
    };
    observations_for_client: {
      show: boolean;
      show_in_bulk: boolean;
      mandatory: boolean;
    };
    emails: {
      show: boolean;
      show_in_bulk: boolean;
      mandatory: boolean;
    };
    signature: {
      show: boolean;
      show_in_bulk: boolean;
      options: Array<{
        id: string;
        label: string;
      }>;
    };
    attachments: {
      show: boolean;
      show_in_bulk: boolean;
    };
  };
};

export class CloseTaskActions {
  constructor(public readonly value: CloseTaskActionsData) {}

  shouldShowModalForTask(task: Task) {
    if (this.value.show === CloseTaskActionsShow.ALWAYS) {
      return true;
    }

    if (this.value.show === CloseTaskActionsShow.OUTSIDE_VALID_DATE_RANGE) {
      const today = LocalDate.now();
      return !task.date.range.isInRange(today);
    }

    if (this.value.show === CloseTaskActionsShow.NEVER) {
      return false;
    }

    // otherwise
    return false;
  }

  shouldShowPredefinedObservations(bulkMode: boolean = false): boolean {
    if (bulkMode) {
      return this.value.fields.predefined_observations.show_in_bulk;
    }

    return this.value.fields.predefined_observations.show;
  }

  shouldShowResolutions(bulkMode: boolean = false): boolean {
    if (!this.value.fields.resolutions) {
      return false;
    }

    if (bulkMode) {
      return this.value.fields.resolutions.show_in_bulk;
    }

    return this.value.fields.resolutions.show;
  }

  shouldShowObservations(bulkMode: boolean = false) {
    if (bulkMode) {
      return this.value.fields.observations_for_client.show_in_bulk;
    }

    return this.value.fields.observations_for_client.show;
  }

  isObservationsMandatory() {
    return this.value.fields.observations_for_client.mandatory;
  }

  shouldShowEmails(bulkMode: boolean = false): boolean {
    if (bulkMode) {
      return this.value.fields.emails.show_in_bulk;
    }

    return this.value.fields.emails.show;
  }

  isEmailsMandatory() {
    return this.value.fields.emails.mandatory;
  }

  shouldShowSignature(bulkMode: boolean = false) {
    if (bulkMode) {
      return this.value.fields.signature.show_in_bulk;
    }

    return this.value.fields.signature.show;
  }

  shouldShowAttachments(bulkMode: boolean = false) {
    if (bulkMode) {
      return this.value.fields.attachments.show_in_bulk;
    }

    return this.value.fields.attachments.show;
  }

  isBeforeValidRangeDate(currentDate: LocalDate, taskDate: TaskDate): boolean {
    return currentDate.atom < taskDate.range.from.atom;
  }

  isAfterValidRangeDate(currentDate: LocalDate, taskDate: TaskDate): boolean {
    return currentDate.atom > taskDate.range.to.atom;
  }

  optionsForDate(currentDate: LocalDate, taskDate: TaskDate): Array<{ id: string; label: string }> {
    const isBeforeDate = this.isBeforeValidRangeDate(currentDate, taskDate);
    const isAfterDate = this.isAfterValidRangeDate(currentDate, taskDate);
    const allOptions = this.value.fields.predefined_observations.options;

    if (isBeforeDate) {
      return allOptions
        .filter((o) => o.condition === 'done_before')
        .map((o) => ({
          id: o.id,
          label: o.name,
        }));
    }

    if (isAfterDate) {
      return allOptions
        .filter((o) => o.condition === 'done_after')
        .map((o) => ({
          id: o.id,
          label: o.name,
        }));
    }

    return [];
  }

  optionsForResolutions(): Array<{ id: string; label: string }> {
    const allOptions = this.value.fields.resolutions.options;

    return allOptions.map((o) => ({
      id: o.id,
      label: o.name,
    }));
  }

  signatureOptions(): Array<{ id: string; label: string }> {
    return this.value.fields.signature.options;
  }
}
