import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ApiException } from './api-exception';
import { BadRequestException } from './bad-request-exception';
import { InternalServerException } from './internal-server-exception';
import { UnauthorizedException } from '../../../authentication/domain/exceptions/unauthorized-exception';
import { GenericClientException } from './generic-client-exception';
import { ClientException } from './client-exception';
import { TaskAlreadyClosedException } from './task-already-closed-exception';
import { UserHasNoRolesException } from './user-has-no-roles-exception';
import { AmbitNotFoundException } from './ambit-not-found-exception';
import { MasterDataStructureInvalidException } from './master-data-structure-invalid-exception';

@Injectable({
  providedIn: 'any',
})
export class ExceptionManagerService {
  public static TASK_ALREADY_CLOSED = 'TASK_ALREADY_CLOSED';
  public static USER_HAS_NO_ROLES = 'USER_HAS_NO_ROLES';

  constructor() {}

  manage(exception: HttpErrorResponse | ApiException): ApiException | ClientException {
    if (exception instanceof ApiException) {
      // RequestTooLargeException, Timeout, NoConnection, ServerIsGone, etc.
      return exception;
    }

    if (exception instanceof HttpErrorResponse) {
      switch (exception.status) {
        case HttpStatusCode.Unauthorized:
          return UnauthorizedException.fromError(exception);
        case HttpStatusCode.NotFound:
          return AmbitNotFoundException.fromError(exception);
        case HttpStatusCode.BadRequest:
          if (exception.error.reason === ExceptionManagerService.TASK_ALREADY_CLOSED) {
            return TaskAlreadyClosedException.fromError(exception);
          }
          if (exception.error.reason === ExceptionManagerService.USER_HAS_NO_ROLES) {
            return UserHasNoRolesException.fromError(exception);
          }
          return BadRequestException.fromError(exception);
        case HttpStatusCode.Conflict:
          return MasterDataStructureInvalidException.fromError(exception);
      }

      // otherwise, internal server exception
      return InternalServerException.fromError(exception);
    }

    return GenericClientException.fromError(exception);
  }
}
