import { ClientException, ClientExceptionConstructor } from './client-exception';

export class MasterDataBadFormatException extends ClientException implements ClientExceptionConstructor {
  showDefaultMessage = false;

  constructor(message?: string, error?: Error) {
    super(message, error);
    this.name = 'MasterDataBadFormatException';
  }

  static fromError(error: Error): MasterDataBadFormatException {
    return new this(error.message, error);
  }
}
