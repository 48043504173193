import { HttpErrorResponse } from '@angular/common/http';
import { ApiException, ApiExceptionConstructor } from 'src/core/common/domain/exceptions/api-exception';

export class AmbitNotFoundException extends ApiException implements ApiExceptionConstructor {
  constructor(message?: string, error?: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'AmbitNotFoundException';
  }

  static fromError(error: HttpErrorResponse | Error): AmbitNotFoundException {
    return new this(error.message, error);
  }
}
