import { Nullable } from '../../common/domain/types/types';

export class Ambit {
  constructor(
    public readonly id: string,
    public readonly code: string,
    public readonly name: string,
    public readonly fullAmbitPrintable: string,
    public readonly parentId: Nullable<string>
  ) {}
}
