import { Nullable } from '../../common/domain/types/types';

export class Resolution {
  constructor(public readonly id: string, public readonly name: string) {}

  static fromPrimitives(primitives: Nullable<{ id: string; name: string }>) {
    if (primitives === null) {
      return null;
    }

    return new Resolution(primitives.id, primitives.name);
  }
}
