export class TaskStatus {
  public static readonly PENDING: string = 'pending';
  public static readonly IN_PROGRESS: string = 'in_progress';
  public static readonly CLOSED: string = 'closed';
  public static readonly CLOSED_CONFIRMED: string = 'closed_confirmed';
  public static readonly REJECTED: string = 'rejected';
  public static readonly REJECTED_CONFIRMED: string = 'rejected_confirmed';

  private constructor(public readonly value: string) {
    this.ensureStatusIsValid(value);
  }

  static fromPrimitives(status: string): TaskStatus {
    return new this(status);
  }

  static pending(): TaskStatus {
    return new TaskStatus(TaskStatus.PENDING);
  }

  static inProgress(): TaskStatus {
    return new TaskStatus(TaskStatus.IN_PROGRESS);
  }

  static closed(): TaskStatus {
    return new TaskStatus(TaskStatus.CLOSED);
  }

  static closedConfirmed(): TaskStatus {
    return new TaskStatus(TaskStatus.CLOSED_CONFIRMED);
  }

  static rejected(): TaskStatus {
    return new TaskStatus(TaskStatus.REJECTED);
  }

  static rejectedConfirmed(): TaskStatus {
    return new TaskStatus(TaskStatus.REJECTED_CONFIRMED);
  }

  isPending(): boolean {
    return this.value === TaskStatus.PENDING;
  }

  isInProgress(): boolean {
    return this.value === TaskStatus.IN_PROGRESS;
  }

  isClosed(): boolean {
    return this.value === TaskStatus.CLOSED;
  }

  isClosedConfirmed(): boolean {
    return this.value === TaskStatus.CLOSED_CONFIRMED;
  }

  isRejected(): boolean {
    return this.value === TaskStatus.REJECTED;
  }

  isRejectedConfirmed(): boolean {
    return this.value === TaskStatus.REJECTED_CONFIRMED;
  }

  toPrimitives(): string {
    return this.value;
  }

  private ensureStatusIsValid(status: string) {
    const validStatus = [
      TaskStatus.PENDING,
      TaskStatus.IN_PROGRESS,
      TaskStatus.CLOSED,
      TaskStatus.CLOSED_CONFIRMED,
      TaskStatus.REJECTED,
      TaskStatus.REJECTED_CONFIRMED,
    ];
    if (validStatus.indexOf(status) === -1) {
      throw new Error(`Status <${status}> is not valid`);
    }
  }
}
