import { MasterDataRepository } from 'src/core/master-data/domain/master-data-repository';
import { MasterData } from 'src/core/master-data/domain/master-data';
import { HttpClient } from 'src/core/common/domain/http/http-client';
import { ServiceAddress } from 'src/core/master-data/domain/service-address';
import { Ambit } from 'src/core/master-data/domain/ambit';
import { Injectable } from '@angular/core';
import { Assignee, FilterDate, Filters } from 'src/core/master-data/domain/filters';
import { Location, Task } from 'src/core/master-data/domain/task';
import { TaskStatus } from 'src/core/master-data/domain/task-status';
import { ApiTaskResponse } from './api-task-response';
import { CloseTaskActions, CloseTaskActionsData } from '../domain/close-task-actions';
import { ApiGetMasterDataResponse } from './api-master-get-data-response';
import { DefaultFilters } from '../domain/default-filters';
import { WorkType } from '../work-type';
import { ApiGetMasterDataCloseTaskActionsResponse } from './api-get-master-data-close-task-actions-response';
import { Translation } from 'src/core/common/domain/translation/translation';
import { MasterDataConfiguration, MasterDataConfigurationData } from '../domain/master-data-configuration';
import { ApiService } from '../../api/domain/api.service';
import { ExceptionManagerService } from '../../common/domain/exceptions/exception-manager.service';
import { ApiAccessService } from '../../common/domain/api-access/api-access.service';
import { RejectTaskActions, RejectTaskActionsData } from '../domain/reject-task-actions';
import { ApiGetMasterDataRejectTaskActionsResponse } from './api-get-master-data-reject-task-actions-response';
import { ApiGetMasterDataConfigurationResponse } from './api-get-master-data-configuration-response';
import { ApiGetMasterDataFiltersResponse } from './api-get-master-data-filters-response';
import { NoConnectionException } from '../../common/domain/exceptions/no-connection-exception';
import { ConnectivityService } from '../../connection/domain/connectivity.service';
import { GeolocationHelper } from '../../common/domain/geolocation/geolocation-helper';
import { ApiGetMasterDataConfirmCloseTaskActionsResponse } from './api-get-master-data-confirm-close-task-actions-response';
import { ConfirmCloseTaskActions, ConfirmCloseTaskActionsData } from '../domain/confirm-close-task-actions';
import { ConfirmRejectTaskActions, ConfirmRejectTaskActionsData } from '../domain/confirm-reject-task-actions';
import { ApiGetMasterDataConfirmRejectTaskActionsResponse } from './api-get-master-data-confirm-reject-task-actions-response';
import { MasterDataBadFormatException } from '../../common/domain/exceptions/master-data-bad-format-exception';
import { ApiException } from '../../common/domain/exceptions/api-exception';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class HttpMasterDataRepository extends MasterDataRepository {
  constructor(
    private readonly apiAccessService: ApiAccessService,
    private readonly apiService: ApiService,
    private readonly client: HttpClient,
    private readonly connectivityService: ConnectivityService,
    private readonly exceptionManager: ExceptionManagerService,
    private readonly translation: Translation
  ) {
    super();
  }

  async load(): Promise<MasterData> {
    return new Promise<MasterData>(async (resolve, reject) => {
      try {
        if (!this.connectivityService.isOnline()) {
          reject(this.exceptionManager.manage(new NoConnectionException()));
          return;
        }

        await this.apiAccessService.renewApiAccessIfNeeded();
        const url = `${this.apiService.baseUrl()}/master-data.get`;
        const response = await this.client.get<ApiGetMasterDataResponse>(url, {}, {}, true, true);
        const masterData = this.buildMasterDataResponse(response);
        resolve(masterData);
      } catch (exception) {
        const isApiException = exception instanceof ApiException;
        const isHttpError = exception instanceof HttpErrorResponse;
        if (!isApiException && !isHttpError) {
          // we assume that if we get a javascript error, it is caused by a problem with master data format
          const error = MasterDataBadFormatException.fromError(exception);
          const errorMessage = this.translation.instant('MESSAGES.MASTER_DATA_BAD_FORMAT', { code: error.code });
          error.overrideMessage(errorMessage);
          reject(error);
          return;
        }

        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  private buildMasterDataResponse(response: ApiGetMasterDataResponse) {
    const serviceAddresses: Array<ServiceAddress> = [];
    const ambitList: Array<Ambit> = this.buildAmbitListFromApiResponse(response);
    const configuration = this.buildConfigurationFromApiResponse(response.configuration);
    const filters: Filters = this.buildFiltersFromApiResponse(response);
    const tasks: Array<Task> = this.buildTasksFromApiResponse(response, configuration);
    const availableActions = response.available_actions;
    const closeTaskActions = this.buildCloseTaskActionsFromApiResponse(response.close_task_actions);
    const confirmCloseTaskActions = this.buildConfirmCloseTaskActionsFromApiResponse(
      response.confirm_close_task_actions
    );
    const rejectTaskActions = this.buildRejectTaskActionsFromApiResponse(response.reject_task_actions);
    const confirmRejectTaskActions = this.buildConfirmRejectTaskActionsFromApiResponse(
      response.confirm_reject_task_actions
    );

    return new MasterData(
      serviceAddresses,
      filters,
      ambitList,
      tasks,
      availableActions,
      closeTaskActions,
      confirmCloseTaskActions,
      rejectTaskActions,
      confirmRejectTaskActions,
      configuration,
      false
    );
  }

  private buildCloseTaskActionsFromApiResponse(
    closeTaskActions: ApiGetMasterDataCloseTaskActionsResponse
  ): CloseTaskActions {
    return new CloseTaskActions(this.prepareCloseTaskActions(closeTaskActions));
  }

  private prepareCloseTaskActions(
    inputCloseTaskActions: ApiGetMasterDataCloseTaskActionsResponse
  ): CloseTaskActionsData {
    const signatureOptions = inputCloseTaskActions.fields.signature.options;
    const signatureOptionsWithLabels = signatureOptions.map((o) => {
      const key = `TASK.CLOSE_TASK_MODAL.SIGNATURE_OPTIONS.${o.id.toUpperCase()}`;
      let label = this.translation.instant(key);
      if (label === key) {
        label = o.id;
      }
      return { id: o.id, label };
    });

    if (inputCloseTaskActions.fields.predefined_observations.show === undefined) {
      inputCloseTaskActions.fields.predefined_observations.show = false;
    }

    if (inputCloseTaskActions.fields.resolutions && inputCloseTaskActions.fields.resolutions.show === undefined) {
      inputCloseTaskActions.fields.resolutions.show = false;
    }

    // transform { code, name } to { id, name }
    const transformedResolutions = inputCloseTaskActions.fields.resolutions.options.map((option) => ({
      id: option.id,
      name: option.name,
      isFinal: option.is_final,
    }));

    // set show_bulk_mode to false by default if not informed
    this.establishFallbackBulkModeForCloseTaskActions(inputCloseTaskActions);

    const closeTaskActions = JSON.parse(JSON.stringify(inputCloseTaskActions)) as CloseTaskActionsData;
    closeTaskActions.fields.signature.options = signatureOptionsWithLabels;
    closeTaskActions.fields.resolutions.options = transformedResolutions;

    return closeTaskActions;
  }

  private buildConfirmCloseTaskActionsFromApiResponse(
    confirmCloseTaskActions: ApiGetMasterDataConfirmCloseTaskActionsResponse
  ): ConfirmCloseTaskActions {
    return new ConfirmCloseTaskActions(this.prepareConfirmCloseTaskActions(confirmCloseTaskActions));
  }

  private prepareConfirmCloseTaskActions(
    inputConfirmCloseTaskActions: ApiGetMasterDataConfirmCloseTaskActionsResponse
  ): ConfirmCloseTaskActionsData {
    if (
      inputConfirmCloseTaskActions.fields.resolutions &&
      inputConfirmCloseTaskActions.fields.resolutions.show === undefined
    ) {
      inputConfirmCloseTaskActions.fields.resolutions.show = false;
    }

    // transform { code, name, is_final } to { id, name, isFinal }
    const transformedResolutions = inputConfirmCloseTaskActions.fields.resolutions.options.map((option) => ({
      id: option.id,
      name: option.name,
      isFinal: option.is_final,
    }));

    const confirmCloseTaskActions = JSON.parse(
      JSON.stringify(inputConfirmCloseTaskActions)
    ) as ConfirmCloseTaskActionsData;
    confirmCloseTaskActions.fields.resolutions.options = transformedResolutions;

    return confirmCloseTaskActions;
  }

  private establishFallbackBulkModeForCloseTaskActions(
    inputCloseTaskActions: ApiGetMasterDataCloseTaskActionsResponse
  ) {
    inputCloseTaskActions.fields.observations_for_client.show_in_bulk =
      inputCloseTaskActions.fields.observations_for_client.show_in_bulk ?? true;
    inputCloseTaskActions.fields.predefined_observations.show_in_bulk =
      inputCloseTaskActions.fields.predefined_observations.show_in_bulk ?? false;
    inputCloseTaskActions.fields.resolutions.show_in_bulk =
      inputCloseTaskActions.fields.resolutions.show_in_bulk ?? false;
    inputCloseTaskActions.fields.emails.show_in_bulk = inputCloseTaskActions.fields.emails.show_in_bulk ?? false;
    inputCloseTaskActions.fields.attachments.show_in_bulk =
      inputCloseTaskActions.fields.attachments.show_in_bulk ?? false;
    inputCloseTaskActions.fields.signature.show_in_bulk = inputCloseTaskActions.fields.signature.show_in_bulk ?? false;
  }

  private establishFallbackBulkModeForRejectTaskActions(
    inputRejectTaskActions: ApiGetMasterDataRejectTaskActionsResponse
  ) {
    inputRejectTaskActions.fields.observations_for_client.show_in_bulk =
      inputRejectTaskActions.fields.observations_for_client.show_in_bulk ?? true;
    inputRejectTaskActions.fields.resolutions.show_in_bulk =
      inputRejectTaskActions.fields.resolutions.show_in_bulk ?? false;
    inputRejectTaskActions.fields.predefined_observations.show_in_bulk =
      inputRejectTaskActions.fields.predefined_observations.show_in_bulk ?? false;
  }

  private buildRejectTaskActionsFromApiResponse(
    rejectTaskActions: ApiGetMasterDataRejectTaskActionsResponse
  ): RejectTaskActions {
    return new RejectTaskActions(this.prepareRejectTaskActions(rejectTaskActions));
  }

  private prepareRejectTaskActions(
    inputRejectTaskActions: ApiGetMasterDataRejectTaskActionsResponse
  ): RejectTaskActionsData {
    if (inputRejectTaskActions.fields.predefined_observations.show === undefined) {
      inputRejectTaskActions.fields.predefined_observations.show = false;
    }

    if (inputRejectTaskActions.fields.resolutions && inputRejectTaskActions.fields.resolutions.show === undefined) {
      inputRejectTaskActions.fields.resolutions.show = false;
    }

    const transformedResolutions = inputRejectTaskActions.fields.resolutions.options.map((option) => ({
      id: option.id,
      name: option.name,
      isFinal: option.is_final,
    }));

    // set show_bulk_mode to false by default if not informed
    this.establishFallbackBulkModeForRejectTaskActions(inputRejectTaskActions);

    const rejectTaskActions = JSON.parse(JSON.stringify(inputRejectTaskActions)) as RejectTaskActionsData;
    rejectTaskActions.fields.resolutions.options = transformedResolutions;

    return rejectTaskActions;
  }

  private buildConfirmRejectTaskActionsFromApiResponse(
    confirmRejectTaskActions: ApiGetMasterDataConfirmRejectTaskActionsResponse
  ): ConfirmRejectTaskActions {
    return new ConfirmRejectTaskActions(this.prepareConfirmRejectTaskActions(confirmRejectTaskActions));
  }

  private prepareConfirmRejectTaskActions(
    inputConfirmRejectTaskActions: ApiGetMasterDataConfirmRejectTaskActionsResponse
  ): ConfirmRejectTaskActionsData {
    if (
      inputConfirmRejectTaskActions.fields.resolutions &&
      inputConfirmRejectTaskActions.fields.resolutions.show === undefined
    ) {
      inputConfirmRejectTaskActions.fields.resolutions.show = false;
    }

    const transformedResolutions = inputConfirmRejectTaskActions.fields.resolutions.options.map((option) => ({
      id: option.id,
      name: option.name,
      isFinal: option.is_final,
    }));

    const confirmRejectTaskActions = JSON.parse(
      JSON.stringify(inputConfirmRejectTaskActions)
    ) as ConfirmRejectTaskActionsData;
    confirmRejectTaskActions.fields.resolutions.options = transformedResolutions;

    return confirmRejectTaskActions;
  }

  private buildAmbitListFromApiResponse(response: ApiGetMasterDataResponse) {
    return response.ambit.list
      .filter(
        (ambit) =>
          ambit.full_ambit_printable !== null &&
          ambit.full_ambit_printable !== undefined &&
          ambit.full_ambit_printable.trim() !== ''
      ) // discard NULL full_ambit_printable
      .map((ambit) => {
        const code = ambit.code === '' ? null : ambit.code;
        return new Ambit(ambit.id, code, ambit.name, ambit.full_ambit_printable, ambit.parent_id);
      })
      .sort((a, b) => a.fullAmbitPrintable.localeCompare(b.fullAmbitPrintable));
  }

  private buildFiltersFromApiResponse(response: ApiGetMasterDataResponse): Filters {
    // TODO: Ensure all values are correct?
    this.sanitizeDefaultDateFilter(response.filters);

    const defaultFilters = new DefaultFilters(
      response.filters.default.work_types,
      response.filters.default.status,
      response.filters.default.assignee as Array<Assignee>,
      response.filters.default.date as FilterDate
    );

    // API fallback (to prevent lack of field "work_type.name")
    this.applyWorkTypeNamesApiFallback(response.filters.work_types);

    return new Filters(
      defaultFilters,
      response.filters.work_types.map((work_type) => new WorkType(work_type.id, work_type.name)),
      response.filters.status.map((status) => TaskStatus.fromPrimitives(status.id)),
      response.filters.assignee.map((assignee) => assignee.id as Assignee)
    );
  }

  private buildTasksFromApiResponse(
    response: ApiGetMasterDataResponse,
    configuration: MasterDataConfiguration
  ): Array<Task> {
    return response.tasks.map((task) => this.buildTaskFromApiResponse(task, configuration));
  }

  private buildTaskFromApiResponse(task: ApiTaskResponse, configuration: MasterDataConfiguration) {
    const taskDate = {
      expected: task.planned_date.expected,
      from: task.planned_date.valid_range_start,
      to: task.planned_date.valid_range_end,
    };
    const taskLocation: Location = {
      full_address: '' + task.location.full_address, // force string (temp fix for response being a number)
      geolocation: GeolocationHelper.parseFromAPI(task.location),
    };

    // API fallback (to prevent lack of field "work_type.name")
    this.applyWorkTypeNameApiFallback(task.work_type);

    const workType = {
      id: task.work_type.id,
      name: task.work_type.name,
    };
    const displayServiceAddressInTask = configuration.isDisplayServiceAddressInTask();
    const displayPostalAddressInImmediateTask = configuration.isDisplayPostalAddressInImmediateTask();
    const displayPostalAddressInPlannedTask = configuration.isDisplayPostalAddressInPlannedTask();
    const displayAmbitInImmediateTask = configuration.isDisplayAmbitInImmediateTask();
    const displayAmbitInPlannedTask = configuration.isDisplayAmbitInPlannedTask();
    const displayDescriptionInImmediateTask = configuration.isDisplayDescriptionInImmediateTask();
    const displayDescriptionInPlannedTask = configuration.isDisplayDescriptionInPlannedTask();

    return Task.fromPrimitives({
      id: task.id,
      status: task.status,
      subject: task.subject,
      description: task.description,
      observations_for_client: task.observations_for_client,
      work_type: workType,
      ambit: task.ambit,
      date: taskDate,
      assignee: task.assignee,
      frequency: task.frequency,
      resolution: task.resolution ?? null,
      must_be_modified_by_assignee: task.must_be_modified_by_assignee,
      location: taskLocation,
      started_at: task.started_at,
      updated_at: task.updated_at,
      closed_at: task.closed_at,
      confirm_closed_at: null,
      rejected_at: null,
      confirm_rejected_at: null,
      created_at: task.created_at,
      attachments: [],
      display_service_address_in_task: displayServiceAddressInTask,
      display_postal_address_in_immediate_task: displayPostalAddressInImmediateTask,
      display_postal_address_in_planned_task: displayPostalAddressInPlannedTask,
      display_ambit_in_immediate_task: displayAmbitInImmediateTask,
      display_ambit_in_planned_task: displayAmbitInPlannedTask,
      display_description_in_immediate_task: displayDescriptionInImmediateTask,
      display_description_in_planned_task: displayDescriptionInPlannedTask,
      close_task_data: null,
      reject_task_data: null,
      confirm_close_task_data: null,
      confirm_reject_task_data: null,
    });
  }

  private applyWorkTypeNamesApiFallback(workTypes: Array<{ id: string; name?: string }>) {
    workTypes.map((workType) => this.applyWorkTypeNameApiFallback(workType));
  }

  // TODO: Extract duplicated method or delete
  private applyWorkTypeNameApiFallback(workType: { id: string; name?: string }) {
    if (!workType.name) {
      const key = `FILTERS.WORK_TYPES.${workType.id.toUpperCase()}`;
      let workTypeName = this.translation.instant(key);
      if (workTypeName === key) {
        workTypeName = workType.id;
      }
      workType.name = workTypeName;
    }
  }

  private buildConfigurationFromApiResponse(configurationResponse: ApiGetMasterDataConfigurationResponse) {
    this.applyWorkTypeNamesApiFallback(configurationResponse.create_task_work_types);

    const configuration: MasterDataConfigurationData = {
      display_service_address_in_task: configurationResponse.display_service_address_in_task ?? true,
      display_postal_address_in_immediate_task: configurationResponse.display_postal_address_in_immediate_task ?? true,
      display_postal_address_in_planned_task: configurationResponse.display_postal_address_in_planned_task ?? true,
      display_ambit_in_immediate_task: configurationResponse.display_ambit_in_immediate_task ?? true,
      display_ambit_in_planned_task: configurationResponse.display_ambit_in_planned_task ?? true,
      display_description_in_immediate_task: configurationResponse.display_description_in_immediate_task ?? true,
      display_description_in_planned_task: configurationResponse.display_description_in_planned_task ?? true,
      max_attachments: configurationResponse.max_attachments,
      max_attachment_size: configurationResponse.max_attachment_size * 1_000, // transform KB into bytes
      max_request_size: (configurationResponse.max_request_size ?? 8) * 1_000_000, // transform MB into bytes
      can_create_tasks: configurationResponse.can_create_tasks,
      create_task_work_types: configurationResponse.create_task_work_types,
    };
    return new MasterDataConfiguration(configuration);
  }

  private sanitizeDefaultDateFilter(filters: ApiGetMasterDataFiltersResponse) {
    let sanitizedDefaultDateFilter = filters.default.date;
    if (sanitizedDefaultDateFilter === '7_days_before_and_after') {
      sanitizedDefaultDateFilter = FilterDate.RANGE_SEVEN_DAYS_BEFORE_AND_AFTER;
    }
    if (!Object.values(FilterDate).includes(sanitizedDefaultDateFilter as FilterDate)) {
      sanitizedDefaultDateFilter = FilterDate.TODAY;
    }
    filters.default.date = sanitizedDefaultDateFilter;
  }
}
