import { HttpErrorResponse } from '@angular/common/http';
import { Nullable } from '../types/types';
import { ErrorCode } from './error-code';

export interface ClientExceptionConstructor {
  fromError(error: Error): ClientException;
}

export abstract class ClientException extends Error implements ClientExceptionConstructor {
  public originalError: Nullable<Error> = null;
  public code: Nullable<string> = null;
  // show the default error text to user
  showDefaultMessage = true;

  protected constructor(message?: string, error?: Error) {
    const trueProto = new.target.prototype; // Almacenar el prototipo verdadero
    super(message);
    Object.setPrototypeOf(this, Object.create(trueProto)); // Crear un nuevo objeto con el prototipo verdadero
    this.name = 'ClientException';
    this.setOriginalError(error);
    this.calculateErrorCode();
  }

  // eslint-disable-next-line no-unused-vars
  fromError(error: HttpErrorResponse | Error): ClientException {
    throw new Error('El método fromError debe ser implementado en cada subclase de ClientException');
  }

  // customize the error text shown to user
  overrideMessage(message: string){
    this.message = message;
  }

  protected setOriginalError(error: HttpErrorResponse | Error): void {
    this.originalError = error;
  }

  private calculateErrorCode() {
    this.code = ErrorCode.generateWithPrefix('C');
  }
}
