import { MasterData } from './master-data';
import { Storage } from '../../storage/domain/storage';
import { ServiceAddress } from './service-address';
import { Ambit } from './ambit';
import { Injectable } from '@angular/core';
import { StorableService } from '../../storage/domain/storable-service';
import { Initializable } from '../../common/domain/initializer/initializable';
import { BehaviorSubject, Observable } from 'rxjs';
import { CloseTaskActions, CloseTaskActionsData } from './close-task-actions';
import { Nullable } from '../../common/domain/types/types';
import { Assignee, FilterDate, Filters } from './filters';
import { DefaultFilters } from './default-filters';
import { LocalDate } from '../../common/domain/date/local-date';
import { TasksFilters } from '../../filters/domain/filters-service';
import { MasterDataConfiguration, MasterDataConfigurationData } from './master-data-configuration';
import { RejectTaskActions, RejectTaskActionsData } from './reject-task-actions';
import { DateHelper } from '../../common/domain/date/date-helper';
import { TaskActions } from './task-actions';
import { ConfirmCloseTaskActions, ConfirmCloseTaskActionsData } from './confirm-close-task-actions';
import { ConfirmRejectTaskActions, ConfirmRejectTaskActionsData } from './confirm-reject-task-actions';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService implements StorableService, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();
  private readonly KEY_MASTER_DATA_TIMESTAMP = 'master_data.timestamp';
  private readonly KEY_ERROR_ON_LAST_DOWNLOAD = 'master_data.error_on_download';
  private readonly KEY_IS_LOADED = 'master_data.is_loaded';
  private readonly KEY_FILTERS = 'master_data.filters';
  private readonly KEY_AVAILABLE_ACTIONS = 'master_data.available_actions';
  private readonly KEY_SERVICE_ADDRESSES = 'master_data.service_addresses';
  private readonly KEY_AMBIT_LIST = 'master_data.ambit_list';
  private readonly KEY_CLOSE_TASK_ACTIONS = 'master_data.close_task_actions';
  private readonly KEY_CONFIRM_CLOSE_TASK_ACTIONS = 'master_data.confirm_close_task_actions';
  private readonly KEY_REJECT_TASK_ACTIONS = 'master_data.reject_task_actions';
  private readonly KEY_CONFIRM_REJECT_TASK_ACTIONS = 'master_data.confirm_reject_task_actions';
  private readonly KEY_CONFIGURATION = 'master_data.configuration';
  private isLoaded = false;
  private timestamp: Nullable<string> = null;
  private masterData: Nullable<MasterData> = null;
  private filters: Nullable<Filters> = null;
  private availableActions: Nullable<Array<TaskActions>> = null;
  private serviceAddresses: Nullable<Array<ServiceAddress>> = null;
  private ambitList: Nullable<Array<Ambit>> = null;
  private closeTaskActions: Nullable<CloseTaskActions> = null;
  private confirmCloseTaskActions: Nullable<ConfirmCloseTaskActions> = null;
  private rejectTaskActions: Nullable<RejectTaskActions> = null;
  private confirmRejectTaskActions: Nullable<ConfirmRejectTaskActions> = null;
  private configuration: Nullable<MasterDataConfiguration> = null;
  private lastDownloadCausedError = false;

  constructor(private storage: Storage) {}

  async init(): Promise<void> {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    await this.storage.remove(this.KEY_IS_LOADED);
    await this.storage.remove(this.KEY_ERROR_ON_LAST_DOWNLOAD);
    await this.storage.remove(this.KEY_MASTER_DATA_TIMESTAMP);
    await this.storage.remove(this.KEY_FILTERS);
    await this.storage.remove(this.KEY_AVAILABLE_ACTIONS);
    await this.storage.remove(this.KEY_SERVICE_ADDRESSES);
    await this.storage.remove(this.KEY_AMBIT_LIST);
    await this.storage.remove(this.KEY_CLOSE_TASK_ACTIONS);
    await this.storage.remove(this.KEY_CONFIRM_CLOSE_TASK_ACTIONS);
    await this.storage.remove(this.KEY_REJECT_TASK_ACTIONS);
    await this.storage.remove(this.KEY_CONFIRM_REJECT_TASK_ACTIONS);
    await this.storage.remove(this.KEY_CONFIGURATION);
    this.isLoaded = false;
    this.timestamp = null;
    this.filters = null;
    this.availableActions = null;
    this.serviceAddresses = null;
    this.ambitList = null;
    this.closeTaskActions = null;
    this.confirmCloseTaskActions = null;
    this.rejectTaskActions = null;
    this.confirmRejectTaskActions = null;
    this.configuration = null;
  }

  get(): MasterData {
    return this.masterData;
  }

  isFronlinerPro(): boolean {
    // cover the case of miliseconds after logout where master data is deleted
    if (this.availableActions === null) {
      return false;
    }

    // It is assumed that the user is a Frontliner Pro if it has the available_action 'confirm_close_and_reject'
    return this.availableActions.includes(TaskActions.CONFIRM_CLOSE_AND_REJECT);
  }

  getTimestamp(): Nullable<string> {
    return this.timestamp;
  }

  getLastDownloadCausedError(): boolean {
    return this.lastDownloadCausedError;
  }

  async save(masterData: MasterData): Promise<void> {
    const timestamp = LocalDate.now().atom;
    await this.storage.set(this.KEY_FILTERS, masterData.filters.toPrimitives());
    await this.storage.set(this.KEY_AVAILABLE_ACTIONS, masterData.availableActions);
    await this.storage.set(this.KEY_SERVICE_ADDRESSES, masterData.service_addresses);
    await this.storage.set(this.KEY_AMBIT_LIST, masterData.ambitList);
    await this.storage.set(this.KEY_CLOSE_TASK_ACTIONS, masterData.closeTaskActions.value);
    await this.storage.set(this.KEY_CONFIRM_CLOSE_TASK_ACTIONS, masterData.confirmCloseTaskActions.value);
    await this.storage.set(this.KEY_REJECT_TASK_ACTIONS, masterData.rejectTaskActions.value);
    await this.storage.set(this.KEY_CONFIRM_REJECT_TASK_ACTIONS, masterData.confirmRejectTaskActions.value);
    await this.storage.set(this.KEY_CONFIGURATION, masterData.configuration.value);
    await this.storage.set(this.KEY_ERROR_ON_LAST_DOWNLOAD, masterData.lastDownloadCausedError);
    await this.storage.set(this.KEY_MASTER_DATA_TIMESTAMP, timestamp);
    await this.storage.set(this.KEY_IS_LOADED, true);

    this.filters = masterData.filters;
    this.availableActions = masterData.availableActions;
    this.serviceAddresses = masterData.service_addresses;
    this.ambitList = masterData.ambitList;
    this.closeTaskActions = masterData.closeTaskActions;
    this.confirmCloseTaskActions = masterData.confirmCloseTaskActions;
    this.rejectTaskActions = masterData.rejectTaskActions;
    this.confirmRejectTaskActions = masterData.confirmRejectTaskActions;
    this.configuration = masterData.configuration;
    this.lastDownloadCausedError = masterData.lastDownloadCausedError;
    this.timestamp = timestamp;
    this.masterData = masterData;
    this.isLoaded = true;
  }

  getDefaultFilters(): Nullable<DefaultFilters> {
    if (!this.filters || !this.filters.defaultFilters) {
      return null;
    }

    return this.filters.defaultFilters;
  }

  calculateDateFilterFromDateId(
    dateId: string,
    customRange: { from: string; to: string }
  ): {
    date: { id: string; from: string; to: string };
    customRange: { from: string; to: string };
  } {
    if (dateId === FilterDate.RANGE_SEVEN_DAYS_BEFORE_AND_AFTER) {
      dateId = FilterDate.RANGE;
      // from: 7 días antes
      // to: 7 días después
      customRange = {
        from: LocalDate.fromIsoString(LocalDate.now().date.subtract(7, 'days').format()).ymd,
        to: LocalDate.fromIsoString(LocalDate.now().date.add(7, 'days').format()).ymd,
      };
      return {
        customRange,
        date: {
          id: dateId,
          from: customRange.from,
          to: customRange.to,
        },
      };
    }

    const dateRange = this.getDateRangeForDateOption(dateId, customRange);
    const date = {
      id: dateId,
      from: dateRange.from,
      to: dateRange.to,
    };

    return { date, customRange };
  }

  getDefaultTasksFilters(): Nullable<TasksFilters> {
    const masterDataDefaultFilters = this.getDefaultFilters();
    if (masterDataDefaultFilters === null) {
      return null;
    }

    let dateId = masterDataDefaultFilters.date;
    let customRange: { from: string; to: string };
    if (masterDataDefaultFilters.date === FilterDate.RANGE_SEVEN_DAYS_BEFORE_AND_AFTER) {
      dateId = FilterDate.RANGE;
      // from: 7 días antes
      // to: 7 días después
      customRange = {
        from: LocalDate.fromIsoString(LocalDate.now().date.subtract(7, 'days').format()).ymd,
        to: LocalDate.fromIsoString(LocalDate.now().date.add(7, 'days').format()).ymd,
      };
    } else {
      // from: primer dia del mes anterior
      // to: último dia del mes posterior
      customRange = {
        from: LocalDate.fromIsoString(LocalDate.now().date.subtract(1, 'month').startOf('month').format()).ymd,
        to: LocalDate.fromIsoString(LocalDate.now().date.add(1, 'month').endOf('month').format()).ymd,
      };
    }
    const defaultDateRange = {
      from: LocalDate.fromIsoString(LocalDate.now().date.subtract(7, 'days').format()).ymd,
      to: LocalDate.fromIsoString(LocalDate.now().date.add(7, 'days').format()).ymd,
    };
    const dateRange = this.getDateRangeForDateOption(dateId, defaultDateRange);
    const date = {
      id: dateId,
      from: dateRange.from,
      to: dateRange.to,
    };

    return {
      workType: masterDataDefaultFilters.workTypes,
      status: masterDataDefaultFilters.statuses,
      assigned: masterDataDefaultFilters.assignee,
      date,
      customRange,
    };
  }

  getDateRangeForDateOption(dateId: string, range: { from: string; to: string }): { from: string; to: string } {
    switch (dateId) {
      case FilterDate.THIS_WEEK:
        return DateHelper.thisWeekRange();
      case FilterDate.THIS_MONTH:
        return DateHelper.thisMonthRange();
      case FilterDate.LAST_MONTH:
        return DateHelper.lastMonthRange();
      case FilterDate.RANGE:
        return range;
      default:
      case FilterDate.TODAY:
        return { from: LocalDate.now().ymd, to: LocalDate.now().ymd };
    }
  }

  getCloseTaskActions(): Nullable<CloseTaskActions> {
    return this.closeTaskActions;
  }

  getConfirmCloseTaskActions(): Nullable<ConfirmCloseTaskActions> {
    return this.confirmCloseTaskActions;
  }

  getConfiguration(): Nullable<MasterDataConfiguration> {
    return this.configuration;
  }

  getFilters(): Nullable<Filters> {
    return this.filters;
  }

  getAmbitList(): Nullable<Array<Ambit>> {
    return this.ambitList;
  }

  getAvailableActions(): Nullable<Array<TaskActions>> {
    return this.availableActions;
  }

  getServiceAddresses(): Nullable<Array<ServiceAddress>> {
    return this.serviceAddresses;
  }

  isDataDownloaded() {
    return this.isLoaded;
  }

  getRejectTaskActions(): Nullable<RejectTaskActions> {
    return this.rejectTaskActions;
  }

  getConfirmRejectTaskActions(): Nullable<ConfirmRejectTaskActions> {
    return this.confirmRejectTaskActions;
  }

  async markErrorOnDownload() {
    this.lastDownloadCausedError = true;
    await this.storage.set(this.KEY_ERROR_ON_LAST_DOWNLOAD, this.lastDownloadCausedError);
  }

  defaultAssigneeHasMe(): boolean {
    const defaultFilters = this.getDefaultFilters();
    if (!defaultFilters) {
      return false;
    }

    if (defaultFilters.assignee.length > 0 && defaultFilters.assignee.find((a) => a === Assignee.ME)) {
      return true;
    }

    return false;
  }

  private async initFromStorage(): Promise<void> {
    const filters = await this.storage.get(this.KEY_FILTERS);
    this.filters = filters ? Filters.fromPrimitives(filters) : null;
    this.availableActions = await this.storage.get(this.KEY_AVAILABLE_ACTIONS);
    this.serviceAddresses = await this.storage.get(this.KEY_SERVICE_ADDRESSES);
    this.ambitList = await this.storage.get(this.KEY_AMBIT_LIST);
    const closeTaskActions = await this.storage.get(this.KEY_CLOSE_TASK_ACTIONS);
    this.closeTaskActions = new CloseTaskActions(closeTaskActions as CloseTaskActionsData);
    const confirmCloseTaskActions = await this.storage.get(this.KEY_CONFIRM_CLOSE_TASK_ACTIONS);
    this.confirmCloseTaskActions = new ConfirmCloseTaskActions(confirmCloseTaskActions as ConfirmCloseTaskActionsData);
    const rejectTaskActions = await this.storage.get(this.KEY_REJECT_TASK_ACTIONS);
    this.rejectTaskActions = new RejectTaskActions(rejectTaskActions as RejectTaskActionsData);
    const confirmRejectTaskActions = await this.storage.get(this.KEY_CONFIRM_REJECT_TASK_ACTIONS);
    this.confirmRejectTaskActions = new ConfirmRejectTaskActions(
      confirmRejectTaskActions as ConfirmRejectTaskActionsData
    );
    const configurationData = await this.storage.get(this.KEY_CONFIGURATION);
    this.configuration = new MasterDataConfiguration(configurationData as MasterDataConfigurationData);
    this.timestamp = (await this.storage.get(this.KEY_MASTER_DATA_TIMESTAMP)) ?? null;
    this.lastDownloadCausedError = await this.storage.get(this.KEY_ERROR_ON_LAST_DOWNLOAD);
    this.isLoaded = await this.storage.get(this.KEY_IS_LOADED);

    this.masterData = new MasterData(
      this.serviceAddresses,
      this.filters,
      this.ambitList,
      [],
      this.availableActions,
      this.closeTaskActions,
      this.confirmCloseTaskActions,
      this.rejectTaskActions,
      this.confirmRejectTaskActions,
      this.configuration,
      this.lastDownloadCausedError
    );
  }
}
