import { Injectable, Injector } from '@angular/core';
import { Queue } from './queue';
import { StorableService } from '../../storage/domain/storable-service';
import { Initializable } from '../../common/domain/initializer/initializable';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '../../storage/domain/storage';
import { QueueElementFactory } from './queue-element-factory';
import { QueueElement } from './queue-element';
import { EventsService } from '../../events/events.service';
import { NumPendingElementsChangedEvent } from '../../events/num-pending-elements-changed.event';
import { IsSynchronizingEvent } from '../../events/is-synchronizing.event';
import { CloseTaskQueueElement } from './elements/close-task-queue-element';
import { CloseTask } from '../../tasks/application/close/close-task';
import { TaskQueueElement } from './elements/task-queue-element';
import { CloseTaskRequest } from '../../tasks/application/close/close-task-request';
import { StartTaskQueueElement } from './elements/start-task-queue-element';
import { StartTask } from '../../tasks/application/start/start-task';
import { StartTaskRequest } from '../../tasks/application/start/start-task-request';
import { RejectTaskQueueElement } from './elements/reject-task-queue-element';
import { StopTaskQueueElement } from './elements/stop-task-queue-element';
import { StopTask } from '../../tasks/application/stop/stop-task';
import { StopTaskRequest } from '../../tasks/application/stop/stop-task-request';
import { RejectTask } from '../../tasks/application/reject/reject-task';
import { RejectTaskRequest } from '../../tasks/application/reject/reject-task-request';
import { CreateTaskQueueElement } from './elements/create-task-queue-element';
import { CreateTask } from '../../tasks/application/create/create-task';
import { CreateTaskRequest } from '../../tasks/application/create/create-task-request';
import { Nullable } from '../../common/domain/types/types';
import { BadRequestException } from '../../common/domain/exceptions/bad-request-exception';
import { TaskAlreadyClosedException } from '../../common/domain/exceptions/task-already-closed-exception';
import { ConfirmRejectTaskQueueElement } from './elements/confirm-reject-task-queue-element';
import { ConfirmRejectTask } from '../../tasks/application/confirm-reject/confirm-reject-task';
import { ConfirmRejectTaskRequest } from '../../tasks/application/confirm-reject/confirm-reject-task-request';
import { ConfirmCloseTaskQueueElement } from './elements/confirm-close-task-queue-element';
import { ConfirmCloseTask } from '../../tasks/application/confirm-close/confirm-close-task';
import { ConfirmCloseTaskRequest } from '../../tasks/application/confirm-close/confirm-close-task-request';

@Injectable({
  providedIn: 'root',
})
export class QueueService implements StorableService, Initializable {
  taskIds: string[] = [];
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();
  private queue: Nullable<Queue> = null;
  private readonly KEY_QUEUE = 'queue';

  constructor(private storage: Storage, private eventsService: EventsService, private injector: Injector) {}

  async clear() {
    this.queue = null;
    await this.storage.remove(this.KEY_QUEUE);
    this.eventsService.publishNumPendingElementsChanged(new NumPendingElementsChangedEvent(0));
  }

  async init() {
    await this.initQueue();
    this.eventsService.publishNumPendingElementsChanged(new NumPendingElementsChangedEvent(this.queue.size()));
    this.readySubject.next(true);
  }

  async processQueue() {
    this.eventsService.publishIsSynchronizing(new IsSynchronizingEvent(true));

    let index = 0;
    // iterate all elements, one by one, in the retrieved order (from old to new)
    while (index < this.queueSize()) {
      const queueElement = this.queue.element(index);
      if (!queueElement) {
        break; // end of loop
      }

      try {
        await this.processQueueElement(queueElement, index);
        this.queue.removeElement(queueElement);
        await this.saveQueue();
        this.updateTaskIds();
        this.eventsService.publishNumPendingElementsChanged(new NumPendingElementsChangedEvent(this.queue.size()));
      } catch (exception) {
        if (exception instanceof BadRequestException) {
          if (exception instanceof TaskAlreadyClosedException) {
            // In this exception we remove element from queue since it is discarded, and do all the usual steps too.
            this.queue.removeElement(queueElement);
            await this.saveQueue();
            this.updateTaskIds();
            this.eventsService.publishNumPendingElementsChanged(new NumPendingElementsChangedEvent(this.queue.size()));
            return;
          }
        }

        // if current element has not been processed, we increment index to process the following one
        await this.saveQueue(); // ??
        index++;
      }

      // add some delay because otherwise fake API server will fail
      await this.delay(2000);
    }

    this.eventsService.publishIsSynchronizing(new IsSynchronizingEvent(false));
  }

  hasQueueElementWithTaskId(taskId: string): boolean {
    return this.taskIds.includes(taskId);
  }

  getElements(): QueueElement[] {
    if (this.queue === null) {
      return [];
    }

    return this.queue.elements();
  }

  getNumElements(): number {
    if (this.queue === null) {
      return 0;
    }

    return this.queue.size();
  }

  async enqueue(element: QueueElement) {
    if (!this.elementCanBeEnqueued(element)) {
      return;
    }

    this.queue.enqueue(element);
    this.updateTaskIds();

    await this.saveQueue();
    this.eventsService.publishNumPendingElementsChanged(new NumPendingElementsChangedEvent(this.queue.size()));
  }

  removeElement(element: QueueElement) {
    this.queue.removeElement(element);
  }

  async updateQueueElement(queueElement: QueueElement) {
    this.queue.updateElement(queueElement);
    await this.saveQueue();
  }

  async updateLocalTaskReferences(oldTaskId: string, newTaskId: string) {
    let hasChanged = false;
    for (const queueElement of this.queue.elements()) {
      if (queueElement instanceof TaskQueueElement) {
        if (queueElement.task.id === oldTaskId) {
          queueElement.task.changeId(newTaskId);
          queueElement.publicId = newTaskId;
          hasChanged = true;
        }
      }
    }
    if (hasChanged) {
      await this.saveQueue();
    }
  }

  isEmpty(): boolean {
    if (this.queue === null) {
      return true;
    }

    return this.queue.isEmpty();
  }

  private async processQueueElement(queueElement: QueueElement, index: number) {
    if (queueElement instanceof TaskQueueElement) {
      if (!this.taskQueueElementCanBeProcessed(queueElement, index)) {
        throw new Error('Element cannot be processed because other elements block this one');
      }
      await this.processTaskQueueElement(queueElement);
      return;
    }

    // otherwise…
    throw new Error('Unsupported queue element');
  }

  private delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  private updateTaskIds() {
    const taskIds: string[] = [];
    for (const element of this.queue.elements()) {
      const isTaskQueueElement = element instanceof TaskQueueElement;
      if (isTaskQueueElement) {
        const taskId = element.task.id;
        if (!taskIds.includes(taskId)) {
          taskIds.push(taskId);
        }
      }
    }

    this.taskIds = taskIds;
  }

  private async initQueue() {
    const queue = await this.storage.get(this.KEY_QUEUE);
    if (queue === null) {
      // initialize an empty queue and save it into storage
      this.queue = new Queue();
      await this.saveQueue();
      return;
    }

    const initialElements = [];
    if (queue && Array.isArray(queue) && queue.length > 0) {
      for (const item of queue) {
        const element = QueueElementFactory.fromPayload(item);
        initialElements.push(element);
      }
    }
    this.queue = new Queue(initialElements);
    this.updateTaskIds();
  }

  private async saveQueue() {
    // TODO: Improve by not converting every time (or storing the stringified version of elements)
    const queue = this.queue._elements.map((q) => q.toPrimitives());
    await this.storage.set(this.KEY_QUEUE, queue);
  }

  private async processTaskQueueElement(element: TaskQueueElement) {
    if (element instanceof CloseTaskQueueElement) {
      await this.processCloseTaskQueueElement(element);
    } else if (element instanceof StartTaskQueueElement) {
      await this.processStartTaskQueueElement(element);
    } else if (element instanceof StopTaskQueueElement) {
      await this.processStopTaskQueueElement(element);
    } else if (element instanceof RejectTaskQueueElement) {
      await this.processRejectTaskQueueElement(element);
    } else if (element instanceof CreateTaskQueueElement) {
      await this.processCreateTaskQueueElement(element);
    } else if (element instanceof ConfirmCloseTaskQueueElement) {
      await this.processConfirmCloseTaskQueueElement(element);
    } else if (element instanceof ConfirmRejectTaskQueueElement) {
      await this.processConfirmRejectTaskQueueElement(element);
    } else {
      console.error('TaskQueueElement not implemented', element);
    }
  }

  private async processRejectTaskQueueElement(element: RejectTaskQueueElement) {
    const action = this.injector.get<RejectTask>(RejectTask);
    try {
      await action.execute(RejectTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processConfirmRejectTaskQueueElement(element: ConfirmRejectTaskQueueElement) {
    const action = this.injector.get<ConfirmRejectTask>(ConfirmRejectTask);
    try {
      await action.execute(ConfirmRejectTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processCreateTaskQueueElement(element: CreateTaskQueueElement) {
    const action = this.injector.get<CreateTask>(CreateTask);
    try {
      await action.execute(CreateTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processStopTaskQueueElement(element: StopTaskQueueElement) {
    const action = this.injector.get<StopTask>(StopTask);
    try {
      await action.execute(StopTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processStartTaskQueueElement(element: StartTaskQueueElement) {
    const action = this.injector.get<StartTask>(StartTask);
    try {
      await action.execute(StartTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processCloseTaskQueueElement(element: CloseTaskQueueElement) {
    const action = this.injector.get<CloseTask>(CloseTask);
    try {
      await action.execute(CloseTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private async processConfirmCloseTaskQueueElement(element: ConfirmCloseTaskQueueElement) {
    const action = this.injector.get<ConfirmCloseTask>(ConfirmCloseTask);
    try {
      await action.execute(ConfirmCloseTaskRequest.retry(element));
    } catch (exception) {
      throw exception; // propagate
    }
  }

  private elementCanBeEnqueued(element: QueueElement): boolean {
    if (element instanceof CloseTaskQueueElement) {
      const taskId = element.task.id;
      // if there is not any other CloseTaskQueueElement with same taskId, element can be enqueued
      return (
        this.queue
          .elements()
          .filter((qe) => qe instanceof CloseTaskQueueElement)
          .filter((qe: CloseTaskQueueElement) => qe.task.id === taskId).length === 0
      );
    } else if (element instanceof RejectTaskQueueElement) {
      const taskId = element.task.id;
      // if there is not any other RejectTaskQueueElement with same taskId, element can be enqueued
      return (
        this.queue
          .elements()
          .filter((qe) => qe instanceof RejectTaskQueueElement)
          .filter((qe: RejectTaskQueueElement) => qe.task.id === taskId).length === 0
      );
      // } else if (element instanceof CreateTaskQueueElement) {
    }

    return true;
  }

  private queueSize(): number {
    if (this.queue === null) {
      return 0;
    }

    return this.queue.size();
  }

  private taskQueueElementCanBeProcessed(taskQueueElement: TaskQueueElement, index: number): boolean {
    // if there is any other previous element in queue with the taskId, we cannot process this element
    const queueElements = this.queue.elements();
    for (let i = 0; i < index; i++) {
      const qe = queueElements[i];
      if (qe instanceof TaskQueueElement) {
        if (qe.task.id === taskQueueElement.task.id) {
          return false;
        }
      }
    }

    return true;
  }
}
