import { QueueElement } from './queue-element';
import { CloseTaskQueueElement } from './elements/close-task-queue-element';
import { StartTaskQueueElement } from './elements/start-task-queue-element';
import { RejectTaskQueueElement } from './elements/reject-task-queue-element';
import { StopTaskQueueElement } from './elements/stop-task-queue-element';
import { CreateTaskQueueElement } from './elements/create-task-queue-element';
import { ConfirmCloseTaskQueueElement } from './elements/confirm-close-task-queue-element';
import { ConfirmRejectTaskQueueElement } from './elements/confirm-reject-task-queue-element';

export class QueueElementFactory {
  static map = {
    create: CreateTaskQueueElement,
    start: StartTaskQueueElement,
    stop: StopTaskQueueElement,
    close: CloseTaskQueueElement,
    reject: RejectTaskQueueElement,
    confirm_close: ConfirmCloseTaskQueueElement,
    confirm_reject: ConfirmRejectTaskQueueElement,
  };

  static fromPayload(payload: { id: string; created_at: string; type: string; data: string }): QueueElement {
    const type = payload.type;
    const mapElement = QueueElementFactory.map[type] ?? null;
    if (!mapElement) {
      throw new Error(`QueueElementFactory does not have a type <${type}> implementation`);
    }

    return mapElement.fromPayload(payload);
  }
}
