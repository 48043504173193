import { ServiceAddress } from './service-address';
import { Ambit } from './ambit';
import { Filters } from './filters';
import { Task } from './task';
import { CloseTaskActions } from './close-task-actions';
import { MasterDataConfiguration } from './master-data-configuration';
import { RejectTaskActions } from './reject-task-actions';
import { TaskActions } from './task-actions';
import { ConfirmCloseTaskActions } from './confirm-close-task-actions';
import { ConfirmRejectTaskActions } from './confirm-reject-task-actions';

export class MasterData {
  constructor(
    public readonly service_addresses: Array<ServiceAddress>,
    public readonly filters: Filters,
    public readonly ambitList: Array<Ambit>,
    public readonly tasks: Array<Task>,
    public readonly availableActions: TaskActions[],
    public readonly closeTaskActions: CloseTaskActions,
    public readonly confirmCloseTaskActions: ConfirmCloseTaskActions,
    public readonly rejectTaskActions: RejectTaskActions,
    public readonly confirmRejectTaskActions: ConfirmRejectTaskActions,
    public readonly configuration: MasterDataConfiguration,
    public readonly lastDownloadCausedError: boolean
  ) {}
}
