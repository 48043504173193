export type CredentialsMessage = { username: string | null; password: string | null; serialNumber: string | null };
export type LocationMessage = { locationCode: string };

export class NFCMessageParser {
  static isCredentialsMessage(data: string): boolean {
    return data.trim() === '';
  }

  static isLocationMessage(data: string): boolean {
    return !NFCMessageParser.isCredentialsMessage(data);
  }

  static processCredentialsMessage(data: string, serialNumber: string): CredentialsMessage {
    if (data.trim() === '') {
      return { username: null, password: null, serialNumber };
    } else {
      throw new Error('nfc_code_not_recognized');
    }
  }

  static processLocationMessage(data: string): LocationMessage {
    if (data.trim() === '') {
      throw new Error('credentials_cant_be_used_here');
    }

    return { locationCode: data };
  }
}
